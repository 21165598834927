import React, { useRef, useState, useEffect } from "react";
import { Text, Transformer, Group } from "react-konva";
import { parseTextEffects } from "./utils";

const TextLayer = (props) => {
    const { project, stageSize, detail, selectedDetail, setSelectedDetail, previewOnly, selectedScene, setSelectedScene } = props;
    const textRef = useRef();
    const trRef = useRef();
    const layerRef = useRef();
    const [isDragging, setIsDragging] = useState(false);

    const parseColorValue = (value) => {
        if (typeof value === "string" && value.startsWith("&H")) {
            return "#" + value.substring(2);
        }
        return value;
    };

    Object.entries(detail.params || {}).forEach(([key, value]) => {
        if (typeof value === "string" && value.startsWith("&H")) {
            detail.params[key] = parseColorValue(value);
        }
    });

    // Initial positioning effect
    useEffect(() => {
        const shouldRecalculateDimensions = (value) => {
            return value === "null" || value === "0" || value === 0 || value === undefined;
        };

        if (textRef.current) {
            let newWidth = detail.params.width;
            let newHeight = detail.params.height;
            let newX = detail.params.x;
            let newY = detail.params.y;

            newWidth = shouldRecalculateDimensions(detail?.params?.width) ? textRef.current.textWidth : detail?.params?.width;
            newHeight = shouldRecalculateDimensions(detail?.params?.height) ? textRef.current.textHeight : detail?.params?.height;

            updateDetail({
                newWidth: newWidth,
                newHeight: newHeight,
                newX: newX,
                newY: newY,
            });
        }
    }, [textRef.current, stageSize]);

    // Transform effect
    useEffect(() => {
        if (selectedDetail?.id === detail.id && trRef.current && textRef.current) {
            trRef.current.nodes([textRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedDetail]);

    const updateDetail = ({ node, newX, newY, newWidth, newHeight }) => {
        const scaleX = !node ? 1 : node.scaleX();
        const scaleY = !node ? 1 : node.scaleY();

        // Calculate dimensions
        const x = newX ?? node?.x();
        const y = newY ?? node?.y();
        const width = newWidth ?? node?.width() * scaleX;
        const height = newHeight ?? node?.height() * scaleY;
        const rotation = node?.rotation();

        if (selectedDetail && selectedDetail.id === detail.id) {
            setSelectedDetail((prev) => ({
                ...prev,
                text_ref: textRef.current,
                params: {
                    ...prev.params,
                    x,
                    y,
                    width,
                    height,
                    rotation,
                },
            }));
        } else {
            setSelectedScene((prev) => {
                if (!prev?.steps) return prev;
                return {
                    ...prev,
                    steps: prev.steps.map((step) =>
                        step.id === detail.id ? { ...step, text_ref: textRef.current, params: { ...step.params, x, y, width, height } } : step
                    ),
                };
            });
        }

        if (node) {
            node.scaleX(1);
            node.scaleY(1);
        }
    };

    const handleSelect = () => {
        if (previewOnly) return;

        if (selectedDetail?.id !== detail.id) {
            setSelectedDetail({ ...detail });
            return;
        }
    };

    const handleEdit = () => {
        //Handle click for text editing
        const node = textRef.current;
        const stage = node.getStage();
        const stageBox = stage.container().getBoundingClientRect();
        const textPosition = node.getClientRect();

        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);

        // Style textarea to match text
        Object.assign(textarea.style, {
            position: "absolute",
            top: `${stageBox.top + textPosition.y}px`,
            left: `${stageBox.left + textPosition.x}px`,
            fontSize: `${node.fontSize()}px`,
            fontFamily: node.fontFamily(),
            fontWeight: computeFontStyle(),
            textAlign: node.align(),
            color: node.fill(),
            border: "none",
            padding: "0px",
            margin: "0px",
            overflow: "hidden",
            background: "none",
            outline: "none",
            resize: "none",
            lineHeight: node.lineHeight(),
            width: `${node.width() - node.padding() * 2}px`,
            zIndex: "999",
        });

        textarea.value = detail.params.text;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight + 3}px`;
        textarea.focus();

        // Hide Konva text and transformer

        node.hide();
        trRef.current.hide();

        const removeTextarea = () => {
            setSelectedDetail((prev) => ({
                ...prev,
                params: {
                    ...prev.params,
                    text: textarea.value,
                },
            }));
            textarea.parentNode.removeChild(textarea);
            window.removeEventListener("click", handleOutsideClick);
            if (node) {
                node.show();
            }
            if (trRef.current) {
                trRef.current.show();
            }
        };

        // Event listeners
        textarea.addEventListener("input", () => {
            setSelectedDetail((prev) => ({
                ...prev,
                params: {
                    ...prev.params,
                    text: textarea.value,
                },
            }));
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight + 3}px`;
        });

        textarea.addEventListener("keydown", (e) => {
            if ((e.key === "Enter" && !e.shiftKey) || e.key === "Escape") {
                removeTextarea();
            }
        });

        const handleOutsideClick = (e) => {
            if (e.target !== textarea) {
                removeTextarea();
            }
        };

        setTimeout(() => {
            window.addEventListener("click", handleOutsideClick);
        });
    };

    // Helper function to compute font style
    const computeFontStyle = () => {
        const isBold = detail.params.font_style_bold === -1;
        const isItalic = detail.params.font_style_italic === 1;

        if (isBold && isItalic) return "bold italic";
        if (isBold) return "bold";
        if (isItalic) return "italic";
        return "normal";
    };

    return (
        <Group ref={layerRef}>
            <Text
                ref={textRef}
                text={detail.params.text}
                fontSize={parseFloat(detail.params.font_size)}
                fill={detail.params.font_color}
                align={detail.params.text_align}
                fontFamily={detail.params.font_family}
                fontStyle={computeFontStyle()}
                {...parseTextEffects(detail?.params)}
                draggable={!previewOnly}
                x={parseFloat(detail.params.x)}
                y={parseFloat(detail.params.y)}
                width={parseFloat(detail.params.width)}
                height={parseFloat(detail.params.height)}
                // rotation={parseFloat(detail.params.rotation)}
                onDblClick={handleEdit}
                onMouseDown={(e) => {
                    if (isDragging) {
                        return;
                    }
                    handleSelect();
                }}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={(e) => {
                    updateDetail({ node: e.target });
                    setIsDragging(false);
                }}
                onTransformStart={() => {
                    setIsDragging(true);
                }}
                onTransformEnd={(e) => {
                    const node = textRef.current;
                    updateDetail({ node });
                    setIsDragging(false);
                }}
            />

            {selectedDetail?.id === detail.id && !previewOnly && (
                <Transformer
                    ref={trRef}
                    anchorStroke="#6676FF"
                    borderStroke="#6676FF"
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    rotateEnabled={false}
                    enabledAnchors={["top-left", "top-center", "top-right", "middle-left", "middle-right", "bottom-left", "bottom-center", "bottom-right"]}
                    boundBoxFunc={(oldBox, newBox) => {
                        return newBox.width < 10 || newBox.height < 10 ? oldBox : newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default TextLayer;
