const SubtitleStyles = ({ onSelect, selectedScene, selectedDetail, setSelectedDetail }) => {
    const predefinedStyles = [
        {
            id: "elegant-serif",
            name: "Elegant Serif",
            preview: `Your Text`,
            style: {
               
                font_family: "Playfair Display",
                font_weight: "normal",
                font_size: "16",
                font_color: "#00D7FF",
                font_style_bold: "-1",
                font_style_italic: "0",
                text_stroke_color: "#00D7FF",
                text_shadow_position: "2",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "modern-clean",
            name: "Modern Clean",
            preview: `Your Text`,
            style: {
               
                font_family: "Montserrat",
                font_weight: "normal",
                font_size: "16",
                font_color: "#ffffff",
                font_style_bold: "0",
                font_style_italic: "0",
                text_stroke_color: "#ffffff",
                text_shadow_position: "1",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "dramatic-cinzel",
            name: "Dramatic Cinzel",
            preview: `Your Text`,
            style: {
              
                font_family: "Cinzel",
                font_weight: "normal",
                font_size: "16",
                font_color: "#ff0000",
                font_style_bold: "-1",
                font_style_italic: "0",
                text_stroke_color: "#ff0000",
                text_shadow_position: "2",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "artistic-script",
            name: "Artistic Script",
            preview: `Your Text`,
            style: {
              
                font_family: "Qwitcher Grypen",
                font_weight: "normal 700",
                font_size: "20",
                font_color: "#ffffff",
                font_style_bold: "-1",
                font_style_italic: "0",
                text_stroke_color: "#ffffff",
                text_shadow_position: "2",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "protest-bold",
            name: "Protest Bold",
            preview: `Your Text`,
            style: {
                
                font_family: "Protest Strike",
                font_weight: "normal",
                font_size: "16",
                font_color: "#00ff00",
                font_style_bold: "0",
                font_style_italic: "0",
                text_stroke_color: "#00ff00",
                text_shadow_position: "2",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "protest-bold-white",
            name: "Protest Bold",
            preview: `Your Text`,
            style: {
                
                font_family: "Protest Strike",
                font_weight: "normal",
                font_size: "16",
                font_color: "#ffffff",
                font_style_bold: "0",
                font_style_italic: "0",
                text_stroke_color: "#ffffff",
                text_shadow_position: "2",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "italic-lora",
            name: "Italic Lora",
            preview: `Your Text`,
            style: {
               
                font_family: "Lora",
                font_weight: "italic",
                font_size: "16",
                font_color: "#001AFF",
                font_style_bold: "0",
                font_style_italic: "-1",
                text_stroke_color: "#001AFF",
                text_shadow_position: "2",
                text_shadow_color: "#ffffff",
            },
        },
        {
            id: "noto-elegant",
            name: "Noto Elegant",
            preview: `Your Text`,
            style: {
             
                font_family: "Noto Serif",
                font_weight: "italic",
                font_size: "16",
                font_color: "#ffffff",
                font_style_bold: "-1",
                font_style_italic: "-1",
                text_stroke_color: "#ffffff",
                text_shadow_position: "3",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "sunset-orange",
            name: "Sunset Orange",
            preview: `Your Text`,
            style: {
               
                font_family: "Roboto",
                font_weight: "normal",
                font_size: "16",
                font_color: "#FF6B35",
                font_style_bold: "0",
                font_style_italic: "0",
                text_stroke_color: "#FF6B35",
                text_shadow_position: "2",
                text_shadow_color: "#000000",
            },
        },
        {
            id: "lacquer-neon",
            name: "Lacquer Neon",
            preview: `Your Text`,
            style: {
                position: "bottom",
                font_family: "Lacquer",
                font_weight: "normal",
                font_size: "16",
                font_color: "#B537F2",
                font_style_bold: "-1",
                font_style_italic: "0",
                text_stroke_color: "#8A2BE2",
                text_shadow_position: "3",
                text_shadow_color: "#000000",
            },
        },
        // {
        //     id: "doto-clean",
        //     name: "Doto Clean",
        //     preview: `Your Text`,
        //     style: {
        //         position: "bottom",
        //         font_family: "Doto",
        //         font_weight: "normal",
        //         font_size: "16",
        //         font_color: "#2ECC71",
        //         font_style_bold: "0",
        //         font_style_italic: "0",
        //         text_stroke_color: "#27AE60",
        //         text_shadow_position: "2",
        //         text_shadow_color: "#000000",
        //     },
        // },
        // {
        //     id: "montserrat-gold",
        //     name: "Montserrat Gold",
        //     preview: `Your Text`,
        //     style: {
        //         position: "bottom",
        //         font_family: "Montserrat",
        //         font_weight: "bold",
        //         font_size: "18",
        //         font_color: "#FFD700",
        //         font_style_bold: "-1",
        //         font_style_italic: "0",
        //         text_stroke_color: "#DAA520",
        //         text_shadow_position: "2",
        //         text_shadow_color: "#000000",
        //     },
        // }
    ];

    const handleSelect = (style) => {
        setSelectedDetail((prev) => ({
            ...prev,
            params: { ...prev.params, ...style },
        }));
    };

    return (
        <div className="flex flex-1 relative overflow-hidden rounded-xl">
            {/* {selectedScene.input?.params?.src && (
                <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-[120%] h-[120%] bg-zinc-500/50 z-10 blur-sm opacity-40">
                    {selectedScene.input?.params?.type === "image" && (
                        <img src={selectedScene.input?.params?.src} alt="preview" className="w-full h-full object-cover" />   
                    )}
                    {selectedScene.input?.params?.type === "video" && (
                        <video src={selectedScene.input?.params?.src} alt="preview" className="w-full h-full object-cover" />
                    )}
                </div>
            )} */}


            <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full h-full  bg-lightblue100/50 z-10 ">
                    <div className="w-full h-full bg-dots"></div>
                   
            </div>
            <div className="grid grid-cols-2 gap-4 p-4 items-center justify-between relative z-20 flex-1">
                {predefinedStyles.map((style) => (
                    <button
                        key={style.id}
                        onClick={() => handleSelect(style.style)}
                        className="flex flex-col items-center justify-center p-4 px-5 rounded-xl bg-lightblue300/50 hover:bg-indigo-800/30 relative overflow-hidden border border-lightblue300/80"
                    >
                        <div
                            className="min-h-12 max-h-12 flex items-center justify-center z-20"
                            style={{
                                fontSize: `${style.style.font_size}px`,
                                fontFamily: style.style.font_family,
                                fontWeight: style.style.font_weight,
                                fontStyle: style.style.font_style_italic === "-1" ? "italic" : "normal",
                                color: style.style.font_color,
                                WebkitTextStroke: `1px ${style.style.text_stroke_color}`,
                                textShadow: `${style.style.text_shadow_position}px ${style.style.text_shadow_position}px ${style.style.text_shadow_color}`,
                            }}
                        >
                            {style.preview}
                        </div>

                        {/* <div className="text-xs text-white/30">
                        {style.name}
                    </div> */}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SubtitleStyles;
