import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";
import { listProjects, createProject } from "src/common/services/project";
import CardStudio from "./card-studio";

const ListStudio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [projects, setProjects] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);

    useEffect(() => {
        handleStudios({ opr: "list", pageNum: 1 });
    }, [location]);

    useEffect(() => {
        if (props.toCreate) {
            console.log("props.toCreate :: ", props.toCreate);
            handleStudios({ opr: "create" , project: props.toCreate});
        }
    }, [props.toCreate]);

    const handleStudios = async ({ opr, pageNum, project }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    // TODO: Replace with your video fetching service
                    const list_res = await listProjects({ pageNum });
                    console.log("projects res :: ", list_res);
                    if (location?.state?.reload) {
                        setProjects([]);
                        await new Promise((res) => setTimeout(() => res(), 10));
                        navigate(location.pathname);
                    }

                    if (list_res && pageNum > 1) {
                        setProjects([...projects, ...list_res]);
                    } else {
                        setProjects([...list_res]);
                    }

                    if (list_res.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                } catch (error) {
                    console.error(error);
                }
                break;

            case "create":
                try {
                    const create_res = await createProject({
                        name: project.name,
                        proportion: "9:16",
                        width:"720",
                        height: "1280",
                    });
                    console.log("create_res :: ", create_res);
                    await new Promise((res) => setTimeout(() => res(), 10))

                    await handleStudios({opr:'list'})
                } catch (error) {
                    console.error(error);
                }
                break;  

            default:
                break;
        }
        setFetching(false);
    };

    return (
        <div className="list-studios-container relative slideFromTop flex-1 flex flex-col">
            {((fetching && pageNum === 1) || (fetching && props.paging === "manual")) && <Preloader />}

            <OnBottom
                OnBottom={async (val) => {
                    if (pageNum > 1 && props.paging !== "manual") {
                        await handleStudios({ opr: "list", pageNum });
                    }
                }}
            >
                {projects?.length > 0 && (
                    <div className={`grid gap-6 grid-cols-1 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-4`}`}>
                        {projects.map((project, p) => (
                            // TODO: Replace with your video card component
                           <CardStudio key={p} project={project} />
                        ))}
                    </div>
                )}
                {!fetching && props.paging === "manual" && projects?.length > 19 && (
                    <div
                        className="p-3 cursor-pointer mt-6 flex items-center justify-center border border-lightblue100 text-black/50 rounded-xl hover:text-black/100 hover:bg-lightblue100/30"
                        onClick={async () => {
                            await handleStudios({ opr: "list", pageNum });
                        }}
                    >
                        Load more
                    </div>
                )}
            </OnBottom>
            {projects?.length <= 0 && fetching !== null && (
                <div className="text-sm">No videos found</div>
            )}
        </div>
    );
};

export default ListStudio;
