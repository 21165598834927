import { Stage, Layer, Rect } from "react-konva";
import { useEffect, useRef, useState } from "react";
import ImageLayer from "./layers/image";
import TextLayer from "./layers/text";
import { SubtitleLayer } from "./layers/subtitle";
import VideoPlayer from "src/common/components/video-player/video-player";
import { listSteps } from "src/common/services/step";
import RealEstateFirstPageLayer from "./layers/real-estate-first";
import RealEstateLastPageLayer from "./layers/real-estate-last";
import MergeLayer from "./layers/merge";
const StageContainer = (props) => {
    const {
        previewOnly,
        containerRef,
        project,
        setProject,
        stageSize,
        setStageSize,
        selectedScene,
        setSelectedScene,
        selectedDetail,
        setSelectedDetail,
        scaleX,
        scaleY,

        scene,
    } = props;

    const backgroundRef = useRef(null);
    const stageRef = useRef(null);
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        updateStageSize();
        window.addEventListener("resize", updateStageSize);
        return () => window.removeEventListener("resize", updateStageSize);
    }, [project]);

    // useEffect(() => {

    //     handleSteps({ opr: "list" });
    // }, []);

    const updateStageSize = () => {
        if (containerRef?.current && setStageSize) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            const containerAspectRatio = offsetWidth / offsetHeight;
            const screenAspectRatio = project.width / project.height;

            let newWidth, newHeight;
            if (containerAspectRatio > screenAspectRatio) {
                // Fit by height
                newHeight = offsetHeight;
                newWidth = newHeight * screenAspectRatio;
            } else {
                // Fit by width
                newWidth = offsetWidth;
                newHeight = newWidth / screenAspectRatio;
            }
            setStageSize({
                width: newWidth,
                height: newHeight,
            });
        }
    };

    const handleSteps = async ({ opr }) => {
        switch (opr) {
            case "list":
                const steps_res = await listSteps({ sceneid: selectedScene?.id });
                console.log("steps_res :: ", steps_res);
                // Parse step params if they are strings
                const parsedSteps = steps_res.map((step) => ({
                    ...step,
                    params: typeof step.params === "string" ? JSON.parse(step.params) : step.params,
                }));

                console.log("parsedSteps :: ", parsedSteps);
                // Only update if the steps are different
                // if (JSON.stringify(steps) !== JSON.stringify(parsedSteps)) {
                setSteps(parsedSteps);
                //}
                break;
        }
    };

    // useEffect(() => {
    //     if (setSelectedScene && scene && && backgroundRef.current && !scene.backgroundRef) {
    //         setSelectedScene((prev) => ({ ...prev, backgroundRef: backgroundRef.current, stageRef: stageRef.current }));
    //     }
    // }, [scene]);

    return (
        <>
            {selectedScene?.mode === "preview" && selectedScene?.result && !previewOnly && (
                <div
                    className="absolute left-1/2 -translate-x-1/2 top-0 w-full h-full z-20 flex items-center justify-center"
                    style={{ width: stageSize.width, height: stageSize.height, transform: `scale(${scaleX}, ${scaleY})` }}
                >
                    <VideoPlayer src={selectedScene.result} fluid={true} actions={true} fit={"contain"} />
                </div>
            )}
            {(selectedScene?.mode === "edit" || previewOnly) && (
                <Stage ref={stageRef} width={stageSize.width} height={stageSize.height} scaleX={scaleX} scaleY={scaleY}>
                    <Layer>
                        <Rect width={stageSize.width} height={stageSize.height} fill={"#ffffff"} />
                    </Layer>

                    <>
                        {selectedScene?.background && (
                            <Layer ref={backgroundRef}>
                                <Rect width={project.width} height={project.height} fill={selectedScene.background} />
                            </Layer>
                        )}
                        {/* <Layer>
                            {Boolean(selectedScene?.input?.params?.src) && (
                                <ImageLayer
                                    detail={selectedScene?.input}
                                    previewOnly={previewOnly}
                                    selectedDetail={selectedDetail}
                                    setSelectedDetail={setSelectedDetail}
                                    stageSize={stageSize}
                                />
                            )}
                        </Layer> */}

                        <Layer>
                            {selectedScene?.steps?.map((step, s) => {
                                switch (step.action) {
                                    case "realestate-first-page":
                                        return (
                                            <RealEstateFirstPageLayer
                                                key={s}
                                                detail={step}
                                                previewOnly={previewOnly}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                selectedScene={selectedScene}
                                                setSelectedScene={setSelectedScene}
                                                stageSize={stageSize}
                                            />
                                        );
                                    case "realestate-last-page":
                                        return (
                                            <RealEstateLastPageLayer
                                                key={s}
                                                detail={step}
                                                previewOnly={previewOnly}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                selectedScene={selectedScene}
                                                setSelectedScene={setSelectedScene}
                                                stageSize={stageSize}
                                            />
                                        );
                                    case "subtitles":
                                        return (
                                            <SubtitleLayer
                                                key={s}
                                                detail={step}
                                                project={project}
                                                previewOnly={previewOnly}
                                                selectedScene={selectedScene}
                                                setSelectedScene={setSelectedScene}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                stageSize={stageSize}
                                            />
                                        );
                                    case "merge-videos":
                                        console.log("step.params.scenes :: ", step.params.scenes);
                                        return (
                                            <MergeLayer
                                                key={s}
                                                detail={step}
                                                previewOnly={previewOnly}
                                                scenes={step.params.scenes.split(",")}
                                                stageSize={stageSize}
                                            />
                                        );
                                    case "overlay-text": ///TEXT LAYER
                                        if (step.type === "base64") {
                                            return (
                                                <TextLayer
                                                    key={s}
                                                    detail={step}
                                                    previewOnly={previewOnly}
                                                    selectedDetail={selectedDetail}
                                                    setSelectedDetail={setSelectedDetail}
                                                    selectedScene={selectedScene}
                                                    setSelectedScene={setSelectedScene}
                                                    stageSize={stageSize}
                                                />
                                            );
                                        }

                                    case "slide":
                                        return (
                                            <ImageLayer
                                                key={s}
                                                detail={step}
                                                previewOnly={previewOnly}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                selectedScene={selectedScene}
                                                setSelectedScene={setSelectedScene}
                                                stageSize={stageSize}
                                            />
                                        );

                                    default:
                                        // Handle type-based components
                                        switch (step.type) {
                                            case "image":
                                            case "video":
                                                return (
                                                    <ImageLayer
                                                        key={s}
                                                        detail={step}
                                                        previewOnly={previewOnly}
                                                        selectedDetail={selectedDetail}
                                                        setSelectedDetail={setSelectedDetail}
                                                        selectedScene={selectedScene}
                                                        setSelectedScene={setSelectedScene}
                                                        stageSize={stageSize}
                                                    />
                                                );
                                            // case "text":
                                            //     return (
                                            //         <TextLayer
                                            //             key={s}
                                            //             detail={step}
                                            //             previewOnly={previewOnly}
                                            //             selectedDetail={selectedDetail}
                                            //             setSelectedDetail={setSelectedDetail}
                                            //             stageSize={stageSize}
                                            //         />
                                            //     );
                                            default:
                                                return null;
                                        }
                                }
                            })}
                        </Layer>
                    </>
                </Stage>
            )}
        </>
    );
};

export default StageContainer;
