import React, { useRef, useState, useEffect } from "react";
import { Group, Image, Text, Rect } from "react-konva";
import ImageLayer from "./image";
import iconPhone from "src/common/assets/images/real-estate/icon-phone.svg";
import iconEmail from "src/common/assets/images/real-estate/icon-email.svg";
import iconHouse from "src/common/assets/images/real-estate/icon-house.svg";

const RealEstateLastPageLayer = (props) => {
    const { detail, stageSize, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene, previewOnly } = props;
    const [logoImage, setLogoImage] = useState(null);
    const logoRef = useRef();
    const groupRef = useRef();
    const agentImageRef = useRef();
    const [agentImage, setAgentImage] = useState(null);
    const AGENT_GROUP_WIDTH = 160; // Width of the agent profile group
    const [phoneIcon, setPhoneIcon] = useState(null);
    const [emailIcon, setEmailIcon] = useState(null);
    const [houseIcon, setHouseIcon] = useState(null);

    useEffect(() => {
        // Load logo image
        const img = new window.Image();
        img.crossOrigin = "anonymous";
        img.src = detail.params.logo === "" ? iconHouse : detail.params.logo;
        img.onload = () => {
            setLogoImage(img);
        };
    }, [detail.params.logo]);

    useEffect(() => {
        if (logoImage) {
            const imgWidth = logoImage.width;
            const imgHeight = logoImage.height;
            const aspectRatio = imgWidth / imgHeight;

            // Set desired width
            const targetWidth = 70;
            // Calculate height based on aspect ratio
            const targetHeight = targetWidth / aspectRatio;

            if (logoRef.current) {
                logoRef.current.width(targetWidth);
                logoRef.current.height(targetHeight);
            }
        }
    }, [logoImage]);



    useEffect(() => {
        // Load phone icon
        const phoneImg = new window.Image();
        phoneImg.src = iconPhone;
        phoneImg.onload = () => setPhoneIcon(phoneImg);

        // Load email icon
        const emailImg = new window.Image();
        emailImg.src = iconEmail;
        emailImg.onload = () => setEmailIcon(emailImg);

        // Load house icon
        const houseImg = new window.Image();
        houseImg.src = iconHouse;
        houseImg.onload = () => setHouseIcon(houseImg);
    }, []);

    return (
        <Group ref={groupRef}>
            {/* Background */}
            <Rect x={0} y={0} width={stageSize.width} height={stageSize.height} fill="#ffffff" />

            {/* Agent Profile Section - Centered */}
            <Group x={(stageSize.width - AGENT_GROUP_WIDTH) / 2} y={140}>
                {/* Circular Agent Image */}
                <Group
                    ref={agentImageRef}
                    fill="#f1f1f1"
                    clipFunc={(ctx) => {
                        ctx.beginPath();
                        ctx.arc(80, 80, 80, 0, Math.PI * 2);
                        ctx.closePath();
                    }}
                >
                    {/* Background circle */}
                    <Rect width={160} height={160} fill="#f1f1f1" />
                    
                    {/* Image layer only rendered if agent image exists */}
                    {detail.params.src   && (
                        <ImageLayer
                            detail={detail}
                            x={0}
                            y={0}
                            width={160}
                            height={160}
                            previewOnly={true}
                            selectedDetail={selectedDetail}
                            setSelectedDetail={setSelectedDetail}
                            selectedScene={selectedScene}
                            setSelectedScene={setSelectedScene}
                            stageSize={{ width: 160, height: 160 }}
                            objectFit="cover"
                        />
                    )}
                </Group>

                {/* Agent Name, Title, and Logo Group */}
                <Group x={-28}>
                    <Group x={0} y={190}>
                        <Image image={logoImage} x={0} width={42} height={42} />
                        <Text
                            x={54}
                            width={200}
                            text={detail.params.agent_name || "Agent Name"}
                            fontSize={20}
                            fontStyle="normal 500"
                            fontFamily="Montserrat"
                            fill="#000000"
                            align="left"
                        />

                        <Text x={54} y={27} width={200} text={"Real Estate Agent"} fontSize={12} fontFamily="Montserrat" fill="#000000" align="left" />
                    </Group>
                </Group>
            </Group>

            {/* Contact Info - Bottom Corners */}
            <Group x={10} y={stageSize.height - 40}>
                <Image image={emailIcon} width={24} height={24} />
                <Text
                    x={30}
                    y={6}
                    width={300}
                    text={detail.params.agent_email || "email@example.com"}
                    fontSize={11}
                    fontFamily="Montserrat"
                    fill="#000000"
                    align="left"
                    verticalAlign="middle"
                />
            </Group>

            <Group x={stageSize.width - 120} y={stageSize.height - 40}>
                <Image image={phoneIcon} width={24} height={24} />
                <Text
                    x={10}
                    y={6}
                    width={100}
                    text={detail.params.agent_contact || "000-000-0000"}
                    fontSize={11}
                    fontFamily="Montserrat"
                    fill="#000000"
                    align="right"
                />
            </Group>
        </Group>
    );
};

export default RealEstateLastPageLayer;
