import { useEffect } from "react";

import { PiAlignTopSimple } from "react-icons/pi";
import { PiAlignCenterVerticalSimple } from "react-icons/pi";
import { PiAlignBottomSimple } from "react-icons/pi";
import SubtitleStyles from "../../templates/subtitle-styles";
import UiDSection from "../../components/ui-dsection";
import Font from "./font";
import { positions } from "../../common";

const Subtitle = (props) => {
    const {selectedScene, selectedDetail, setSelectedDetail, stageSize } = props;
    const subtitleDimension = {
        width: selectedDetail.params.font_size * 8,
        height: selectedDetail.params.font_size * 2,
    };
    const fontSizes = [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128];

    const thePositions = positions(stageSize, subtitleDimension);
    // useEffect(() => {
    //     if (!selectedDetail.params.position.label) {
    //         setSelectedDetail((prev) => ({
    //             ...prev,
    //             params: {
    //                 ...prev.params,
    //                 position:thePositions.[1].label
    //                 x: thePositions[1].x,
    //                 y: thePositions[1].y,
                
    //             },
    //         }));
    //     }
    // }, []);

    return (
        <>
            
            <UiDSection title="Subtitle Position">
                <div className="flex-1  flex flex-row gap-4 justify-between">
                    {thePositions.map((pos, a) => (
                        <div
                            key={a}
                            className={`group relative transition w-12 h-12 items-center justify-center flex cursor-pointer rounded-xl hover:bg-lightblue100/40 border border-lightblue100`}
                            onClick={() => {
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, position: pos.label},
                                }));
                            }}
                        >
                            {pos.icon}
                            {/* <div className="absolute bg-white rounded-2xl -top-2/3 -translate-y-1 left-1/2 -translate-x-1/2 py-1 px-3 text-nowrap z-30 text-black text-xs shadow-xl border border-lightblue100 opacity-0 group-hover:opacity-100">
                                {pos.label}
                            </div> */}
                        </div>
                    ))}
                </div>
            </UiDSection>

            <Font stageSiz={stageSize} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} open={false} />
        </>
    );
};

export default Subtitle;
