import Scene from "../../scene/scene";
import VideoPlayer from "src/common/components/video-player/video-player";

const Scenes = (props) => {
    const { project, setProject, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene, stageSize } = props;
    const parsedParams = selectedDetail?.params ? (typeof selectedDetail.params === "string" ? JSON.parse(selectedDetail.params) : selectedDetail.params) : {};
    const scenes = parsedParams.scenes.split(",");
    return (
        <div>
            <div className="flex flex-col gap-6 p-6 pt-0 justify-center items-center overflow-y-auto">
                {scenes?.map((scene,s) => (
                    <div key={s} className="w-full h-52 relative overflow-hidden rounded-xl">
                        <VideoPlayer src={scene} fluid={true} hoverPlay={true} actions={false} fit="contain" />
                    </div>


                    // <Scene
                    //     key={scene.id}
                    //     previewOnly={true}
                    //     scene={scene}
                    //     setProject={setProject}
                    //     project={project}
                    //     selectedScene={selectedScene}
                    //     setSelectedScene={setSelectedScene}
                    //     selectedDetail={selectedDetail}
                    //     setSelectedDetail={setSelectedDetail}
                    //     stageSize={stageSize}
                    // />
                ))}
            </div>
        </div>
    );
};

export default Scenes;
