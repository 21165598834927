import Knob from "src/common/components/form/knob";
import { useState, useEffect } from "react";
import { getNobg } from "src/common/services/generate";

const Enhancement = (props) => {
    const { selectedDetail, setSelectedDetail } = props;
    const [enableNobg, setEnableNobg] = useState(false);

    useEffect(() => {
        if (selectedDetail?.params?.use_nobg) {
            handleEnhancement({ opr: "nobg" });
        }
    }, [selectedDetail?.params?.use_nobg]);

    const handleEnhancement = async ({ opr }) => {
        switch (opr) {
            case "nobg":
                try {
                    if (!selectedDetail?.params?.src) {
                        console.log("No source image available");
                        return;
                    }

                    const MAX_RETRIES = 15;
                    const checkResult = async (initialResponse) => {
                        let response = initialResponse;
                        let retries = 0;
                        
                        while (response[0]?.processing && retries < MAX_RETRIES) {
                            await new Promise(resolve => setTimeout(resolve, 2000));
                            response = await getNobg(selectedDetail.params.src);
                            retries++;
                        }

                        if (retries >= MAX_RETRIES) {
                            console.log("Max retries reached for background removal");
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: {
                                    ...prev.params,
                                    use_nobg: false
                                }
                            }));
                            return initialResponse;
                        }

                        return response;
                    };

                    const up_res = await getNobg(selectedDetail.params.src);
                    console.log("nobg res: ", up_res);

                    const final_res = up_res[0]?.processing ? 
                        await checkResult(up_res) : 
                        up_res;

                    const thesrc = final_res[0]?.result || final_res[0]?.processing || "";

                    setSelectedDetail((prev) => ({
                        ...prev,
                        params: {
                            ...prev.params,
                            src_nobg: thesrc
                        }
                    }));
                } catch (error) {
                    console.log("nobg error: ", error);
                }
                break;
        }
    };

    return (
        <div className="flex flex-row gap-2 items-center justify-between">
            <div className="text-sm">Remove Background</div>
            <Knob 
                value={selectedDetail?.params?.use_nobg} 
                OnChange={(val) => setSelectedDetail((prev) => ({
                    ...prev,
                    params: {
                        ...prev.params,
                        use_nobg: val
                    }
                }))} 
            />
        </div>
    );
};

export default Enhancement;
