export const parseTextEffects = (style = {}) => {
    const effects = {
        shadowColor: "transparent",
        shadowBlur: 0,
        shadowOffset: { x: 0, y: 0 },
        shadowOpacity: 1,
        stroke: "transparent",
        strokeWidth: 0,
    };

    // Parse text-shadow
    if (style.text_shadow_position) {
        effects.shadowOffset.x = parseInt(style.text_shadow_position) || 0;
        effects.shadowOffset.y = parseInt(style.text_shadow_position) || 0;
        effects.shadowBlur =  0;
        effects.shadowColor = isValidColor(style.text_shadow_color) ? style.text_shadow_color : "transparent";
        effects.shadowOpacity = 1;
    }

    // Parse text-stroke
   
    const width = style.text_stroke_width || "1";
    const color = style.text_stroke_color || "transparent";

    effects.strokeWidth = parseInt(width) || 1;
    effects.stroke = isValidColor(color) ? color : "transparent";
    effects.fillAfterStrokeEnabled = true;
    
    return effects;
};

// Helper function to validate color format
const isValidColor = (color) => {
    if (!color) return false;

    // Check for rgba format
    if (color.startsWith("rgba")) {
        return /^rgba\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*,\s*[0-9.]+\s*\)$/.test(color.trim());
    }

    // Check for rgb format
    if (color.startsWith("rgb")) {
        return /^rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)$/.test(color.trim());
    }

    // Check for hex format
    if (color.startsWith("#")) {
        return /^#([0-9A-Fa-f]{3}){1,2}$/.test(color.trim());
    }

    // Only allow 'transparent' as a named color
    return color.trim() === "transparent";
};
