import AdminLayout from "src/layouts/admin-layout";

import { IoAddCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ListStudio from "src/modules/admin/studio/list-studio";
import Popup from "src/common/components/popup/popup";

const Studios = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showCreate, setShowCreate] = useState(false);
    const [project, setProject] = useState({
        name: '',
    });

    const [toCreate, setToCreate] = useState(false);

    return (
        <>
        {showCreate &&
            <Popup className={`md:max-w-400`} OnHide={() => setShowCreate(false)}>
                <div className="flex flex-col flex-1 p-10 gap-4">  
                    <div className="text-lg font-semibold">Create Project</div>
                    <div className="border border-lightblue100 p-2 rounded-xl">
                        <input type="text" placeholder="Name" value={project.name} onChange={(e) => setProject({...project, name: e.target.value})} />
                    </div>
                    <button className="button" onClick={() => {
                        setShowCreate(false);
                        setToCreate(project);
                    }}>Create</button>
                </div>
                
            </Popup>
}
            <AdminLayout
                header={(
                    <div className="flex flex-1 items-center justify-between">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="text-lg md:text-xl font-semibold">Studio</div>
                            <button className="button" onClick={() => setShowCreate(true)}>Create</button>
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListStudio toCreate={toCreate} />
                </div>
            </AdminLayout>
        </>
    );
};

export default Studios;
