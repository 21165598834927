import { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";

const UiDSection = (props) => {
    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (props.open !== undefined) {
            setOpen(props.open);
        }
    }, [props.open]);

    return (
        <div className={`d-section border-b border-lightblue100 flex flex-col ${props.className} ${props.padded !== false ? "p-6" : ""}`}>
            {props.title && (
                <div
                    className={`d-title  flex flex-row items-center justify-between relative ${open ? "pb-6" : "z-40"} ${props.padded === false ? "p-6" : ""}`}
                >
                    <div className="uppercase text-2xs  font-semibold flex items-center gap-2">
                        <div className="flex opacity-40">{props.title}</div>
                        {props.required && <span className="text-sm text-red-500">*</span>}
                    </div>
                    <RiArrowDownSLine
                        className={`transition-transform text-lg cursor-pointer opacity-40 hover:opacity-100 ${open ? "rotate-180" : ""}`}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    />
                </div>
            )}

            <div className={` flex-1 relative flex flex-col gap-6 ${open ? "" : "max-h-0 opacity-0"}`}>{props.children}</div>
        </div>
    );
};

export default UiDSection;
