import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import Media from "./media";

const RealEstateFirst = (props) => {
    const { selectedScene, selectedDetail, setSelectedDetail } = props;

    const parsedParams = selectedDetail?.params ? (typeof selectedDetail.params === "string" ? JSON.parse(selectedDetail.params) : selectedDetail.params) : {};

    return (
        <div className="flex flex-col">
            <UiDSection title="Logo" required={true}>
                <Media
                    valueObj={{ src: parsedParams.logo }}
                    mediaType="image"
                    onSelect={(valueObj) => {
                        parsedParams.logo = valueObj.src;
                        if (valueObj?.file) {
                            parsedParams.file = valueObj.file;
                        }

                        setSelectedDetail((prev) => ({
                            ...prev,
                            params: { ...prev.params, ...parsedParams },
                        }));
                    }}
                />

                <div className="text-xs opacity-50">Logo should be 100x100 px</div>
            </UiDSection>

            <UiDSection title="Price" required={true}>
                <div className="border border-lightblue100 rounded-xl relative overflow-hidden flex items-center">
                    <div className="p-2 w-10 flex items-center justify-center opacity-50">$</div>
                    <input
                        type="text"
                        placeholder="Add Price"
                        className="w-full flex-1  p-2 !pl-0"
                        value={parsedParams.price}
                        onChange={(e) => {
                            // Remove any non-digit characters
                            const numericValue = e.target.value.replace(/\D/g, "");
                            // Format number with commas
                            const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, price: formattedValue },
                            }));
                        }}
                    />
                </div>
            </UiDSection>

            <UiDSection title="Address" required={true}>
                <div className="border border-lightblue100 rounded-xl relative overflow-hidden">
                    <textarea
                        placeholder="Add Address"
                        className="w-full !p-4 min-h-[100px]"
                        value={parsedParams.address}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, address: e.target.value },
                            }))
                        }
                    />
                </div>

                <div className="flex gap-4">
                    <div className="flex flex-col gap-2">
                        <div className="text-xs font-semibold uppercase opacity-50">Beds</div>
                        <input
                            type="number"
                            placeholder="Beds"
                            className="w-full border border-lightblue100 rounded-xl p-2"
                            value={parsedParams.beds}
                            onChange={(e) =>
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, beds: e.target.value },
                                }))
                            }
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <div className="text-xs font-semibold uppercase opacity-50">Baths</div>
                        <input
                            type="number"
                            placeholder="Baths"
                            className="w-full border border-lightblue100 rounded-xl p-2"
                            value={parsedParams.baths}
                            onChange={(e) =>
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, baths: e.target.value },
                                }))
                            }
                        />
                    </div>

                    <div className="flex flex-col gap-2">
                        <div className="text-xs  font-semibold uppercase opacity-50">Sqft</div>
                        <input
                            type="number"
                            placeholder="Sqft"
                            className="w-full border border-lightblue100 rounded-xl p-2"
                            value={parsedParams.sqft}
                            onChange={(e) =>
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, sqft: e.target.value },
                                }))
                            }
                        />
                    </div>
                </div>
            </UiDSection>
            {/* 
            <UiDSection title="Agent Information" required={true}>
                <div className="flex flex-col gap-4">
                    <input
                        type="text"
                        placeholder="Agent Name"
                        className="w-full border border-lightblue100 rounded-xl p-2"
                        value={parsedParams.agent_name}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, agent_name: e.target.value },
                            }))
                        }
                    />
                    <input
                        type="email"
                        placeholder="Agent Email"
                        className="w-full border border-lightblue100 rounded-xl p-2"
                        value={parsedParams.agent_email}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, agent_email: e.target.value },
                            }))
                        }
                    />
                    <input
                        type="tel"
                        placeholder="Agent Contact"
                        className="w-full border border-lightblue100 rounded-xl p-2"
                        value={parsedParams.agent_contact}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, agent_contact: e.target.value },
                            }))
                        }
                    />
                </div>
            </UiDSection> */}
        </div>
    );
};

export default RealEstateFirst;
