import Slider from "src/common/components/slider/slider";
import { useState, useEffect } from "react";

const RangeSlider = ({ title, initialValue, min, max, step, OnValue }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
       
        if(OnValue) {
            OnValue(value);
        }
    }, [value]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between pb-5">
                <div className="text-sm capitalize">{title}</div>
                <div>
                    <input 
                        className="min-w-[100px] text-right outline-none"
                        type="number" 
                        value={value} 
                        onChange={(e) => {
                            const newValue = Math.max(min, Math.min(max, Number(e.target.value)));
                            setValue(newValue);
                        }} 
                        max={max}
                        min={min}
                    />
                </div>
            </div>
            <Slider
                initialValue={value}
                value={value}
                min={min}
                max={max}
                step={step}
                OnValue={(val) => {
                    
                    setValue(val);
                }}
            />
        </div>
    );
};

export default RangeSlider;
