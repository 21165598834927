import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";

// QUERIES

export const getVideos = async ({ vtype, pageNum }) => {
    const token = getStoredUser()?.token;
    pageNum = pageNum || 1;

    vtype = vtype ? `/${vtype}` : "";

    const config = {
        method: "get",
        url: `${endpoint.rest}generate${vtype}/all?orderby=createdate desc&pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getVideo = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}generate/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

// MUTATIONS

export const generateVideo = async ({ type, name, text, usevoice, imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender }) => {
    const token = getStoredUser()?.token;
    if (type) {
        const config = {
            method: "post",
            url: `${endpoint.rest}generate/${type}`,
            data: {
                name,
                usevoice,
                text,
                ...(type === "video" && { fix: "yes" }),
                ...(imageurl && { imageurl }),
                ...(videourl && { videourl }),
                ...(audiourl && { audiourl }),
                ...(imageid && { imageid }),
                ...(videoid && { videoid }),
                ...(voiceid && { voiceid }),
                ...(voicegender && { voicegender }),
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`,
            },
        };

        try {
            const res = await axios(config);
            return handleRes({ res, config });
        } catch (error) {
            handleErr({ error, config });
        }
    } else {
        //console.log("missing type");
    }
};

export const deleteVideo = async ({ videoid }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "delete",
        url: `${endpoint.rest}generate/${videoid}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const overlayer = async (input) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/overlay`,
        data: {
            value: JSON.stringify(input),
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const addAudio = async ({ video, audio, volume }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/addaudio`,
        data: {
            video,
            audio,
            volume: volume ? volume : "1",
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const addText = async ({ inputfile, text, x, y, fontsize, font }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/addtext`,
        data: {
            inputfile,
            text,
            fontsize,
            x,
            y,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

//PUBLIC

export const toPublic = async ({ id, value }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}generate/public/${id}`,
        data: { public: value.toString() },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

//STUDIO

export const getStudioVideos = async ({ pageNum }) => {
    const token = getStoredUser()?.token;
    pageNum = pageNum || 1;

    const config = {
        method: "get",
        url: `${endpoint.rest}mediabot/overlay/list?orderby=createdate desc&pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const genUpscale = async ({ opr, video }) => {
    const token = getStoredUser()?.token;

    let theurl;

    if (opr === "get") {
        theurl = `${endpoint.rest}generate/upscaled/${video.id}`;
    } else if (opr === "set") {
        theurl = `${video.result}/upscaled.mp4`;
    }

    const config = {
        method: "get",
        url: theurl,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const genNobg = async ({ opr, video }) => {
    const token = getStoredUser()?.token;

    let theurl;

    if (opr === "get") {
        // theurl = `${endpoint.rest}generate/nobg/${video.id}`;
        theurl = `${endpoint.rest}nobg`;
    } else if (opr === "set") {
        theurl = `${video.result}/nobg.mp4`;
    }

    const config = {
        method: "post",
        url: theurl,
        data: {
            url: video.result,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};


export const getNobg = async (url) => {
    const token = getStoredUser()?.token;


    const config = {
        method: "post",
        url: `${endpoint.rest}nobg`,
        data: {
            url,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
