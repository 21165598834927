import { useEffect, useState } from "react";
import FileUploader from "../../../common/components/file-uploader";
import { createVoice } from "src/common/services/voices";
import { BiUserVoice } from "react-icons/bi";
import Preloader from "src/common/components/preloader/preloader";
import { TbAlertCircle } from "react-icons/tb";
import CoinCard from "../coins/coin-card";
const CreateVoice = (props) => {
    const tabs = [
        {
            id: "upload",
            label: "Upload",
        },
        {
            id: "url",
            label: "URL",
        },
    ];

    const [tab, setTab] = useState(tabs[0]);
    const [fetching, setFetching] = useState(false);

    const [voiceData, setVoiceData] = useState({
        name: "",
        description: "",
        age: "any",
        usecase: "generic",
        gender: "neutral",
        accent: "none",
        fileurl: null,
    });

    useEffect(() => {
        if (props.onUse && props.onUse.url) {
            voiceData.fileurl = props.onUse.url;
            setVoiceData({ ...voiceData });
        }
    }, [props.onUse]);

    const handleCreate = async () => {
        if (voiceData.name !== "" && voiceData.fileurl !== null && voiceData.description !== null) {
            console.log(voiceData);
            setFetching(true);
            try {
                const create_res = await createVoice(voiceData);
                console.log("create_res :: ", create_res);
                props.OnSuccess(true);
            } catch (error) {
                //console.log(error);
                props.OnError(true);
            }
            setFetching(false);
        }
    };

    return (
        <div className="flex flex-col p-10 gap-6 relative overflow-hidden">
            {fetching && <Preloader />}
            <div className="">
                <div className="text-xl font-medium tracking-snug">Create Voice</div>
                
            </div>

            <div className="flex flex-col gap-6 ">
                <div className="flex flex-col gap-2">
                    <div>Name: </div>
                    <div className="border border-lightblue100 border-2xl rounded-2xl">
                        <input
                            type="text"
                            placeholder="Voice name"
                            value={voiceData.name}
                            onChange={(e) => {
                                voiceData.name = e.target.value;
                                setVoiceData({ ...voiceData });
                            }}
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-2">
                    <div>Description: </div>
                    <div className="border border-lightblue100 border-2xl rounded-2xl">
                        <textarea
                            placeholder="Voice Description"
                            type="text"
                            className="p-4"
                            value={voiceData.description}
                            onChange={(e) => {
                                voiceData.description = e.target.value;
                                setVoiceData({ ...voiceData });
                            }}
                        />
                    </div>
                </div>
                {!props.onUse && (
                    <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2">
                        {tabs.map((thetab, t) => (
                            <div
                                key={t}
                                className={`transition flex-1 cursor-pointer ${
                                    thetab.id === tab.id ? "opacity-100 font-semibold bg-white rounded-2xl py-2" : "opacity-50"
                                }`}
                                onClick={() => {
                                    setTab(thetab);
                                }}
                            >
                                {thetab.label}
                            </div>
                        ))}
                    </div>
                )}

                {tab.id === "upload" && (
                    <FileUploader
                        file={voiceData.fileurl}
                        accepts={`video/*`}
                        OnUploading={() => {
                            setFetching(true);
                        }}
                        OnRemove={() => {
                            voiceData.fileurl = null;
                            setVoiceData({ ...voiceData });
                        }}
                        OnSuccess={(fileurl) => {
                            setFetching(false);
                            voiceData.fileurl = fileurl;
                            setVoiceData({ ...voiceData });
                        }}
                    />
                )}
                {tab.id === "url" && (
                    <div className="flex flex-col gap-4 py-2">
                        <input
                            type="text"
                            placeholder="https://your_audio_url_here"
                            className="w-full border border-lightblue100 bg-lightblue50 rounded-2xl"
                            onChange={(e) => {
                                voiceData.fileurl = e.target.value;
                                setVoiceData({ ...voiceData });
                            }}
                        />
                        <div className="opacity-50">Enter audio url.</div>
                    </div>
                )}

                <button
                    disabled={
                        voiceData.name === "" ||
                        voiceData.fileurl === null ||
                        voiceData.fileurl.indexOf("http") === -1 ||
                        voiceData.description === ""
                    }
                    className="button button-small py-3 flex gap-3 items-center justify-between"
                    onClick={async () => {
                        await handleCreate();
                    }}
                >
                    <div className="flex items-center gap-3">
                        {/* <BiUserVoice className="text-xl" /> */}
                        Create Voice
                        </div>

                    <div className="bg-indigo-500/80 rounded-full px-1 py-1  pr-3 flex items-center gap-1 text-xs">
                            <CoinCard coins={"3"} />
                        </div>
                </button>

                {/* <div className="flex items-center gap-1 justify-center text-xs">
                        <CoinCard coins={"3"} /> per generate
                    </div> */}

                <div className="flex gap-2 items-center ">
                    <TbAlertCircle className="text-lg text-amber-400" />{" "}
                    <span className="opacity-40">To achieve optimal results in audio cloning, it's best to use more than 10 seconds of audio.</span>
                </div>
            </div>
        </div>
    );
};

export default CreateVoice;
