import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import Media from "./media";

const RealEstateLast = (props) => {
    const { selectedScene, selectedDetail, setSelectedDetail } = props;

    const parsedParams = selectedDetail?.params ? (typeof selectedDetail.params === "string" ? JSON.parse(selectedDetail.params) : selectedDetail.params) : {};
    

    return (
        <div className="flex flex-col">
            <UiDSection title="Logo" required={true}>
                <Media
                    valueObj={{ src: parsedParams.logo }}
                    mediaType="image"
                    onSelect={(valueObj) => {
                       
                        parsedParams.logo = valueObj.src;
                        if (valueObj?.file) {
                            parsedParams.file = valueObj.file;
                        }

                        setSelectedDetail((prev) => ({
                            ...prev,
                            params: { ...prev.params, ...parsedParams },
                        }));
                    }}
                />
            </UiDSection>

            <UiDSection title="Agent Information" required={true}>
                <div className="flex flex-col gap-4">
                    <input
                        type="text"
                        placeholder="Agent Name"
                        className="w-full border border-lightblue100 rounded-xl !p-3"
                        value={parsedParams.agent_name}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, agent_name: e.target.value },
                            }))
                        }
                    />
                    <input
                        type="email"
                        placeholder="Agent Email"
                        className="w-full border border-lightblue100 rounded-xl p-3"
                        value={parsedParams.agent_email}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, agent_email: e.target.value },
                            }))
                        }
                    />
                    <input
                        type="tel"
                        placeholder="Agent Contact"
                        className="w-full border border-lightblue100 rounded-xl p-3"
                        value={parsedParams.agent_contact}
                        onChange={(e) =>
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: { ...prev.params, agent_contact: e.target.value },
                            }))
                        }
                    />
                </div>
            </UiDSection> 
        </div>
    );
};

export default RealEstateLast;
