import { useState, useEffect } from "react";
import UiDSection from "../../components/ui-dsection";
import UiSelect from "../../components/ui-select";
import RangeSlider from "./range-slider";
import Scenes from "./scenes";

const Transition = (props) => {
    let options = [];
    const { setProject, selectedDetail, setSelectedDetail, project, stageSize, selectedScene, setSelectedScene } = props;
  

 
   
    return (
        <>
            {/* <UiDSection title="Transition">
                <UiSelect
                    options={options}
                    value={selectedDetail.params.option}
                    onChange={(val) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, option: val } }))}
                />

                <RangeSlider
                    title="Duration"
                    initialValue={selectedDetail.params.duration}
                    min={1}
                    max={10}
                    step={0.1}
                    OnValue={(val) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, duration: val } }))}
                />
            </UiDSection> */}
            <UiDSection title="Scenes" padded={false}>
                <Scenes
                    project={project}
                    setProject={setProject}
                    selectedDetail={selectedDetail}
                    setSelectedDetail={setSelectedDetail}
                    stageSize={stageSize}
                    selectedScene={selectedScene}
                    setSelectedScene={setSelectedScene}
                />
            </UiDSection>
        </>
    );
};

export default Transition;
