import VideoPlayer from "src/common/components/video-player/video-player";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import { updateScene } from "src/common/services/scene";
const Input = (props) => {
    const { showInputAdd, setShowInputAdd, selectedScene, selectedDetail, setSelectedDetail, setSelectedScene } = props;
    const [theSrc, setTheSrc] = useState(selectedScene.input?.params?.src);

    useEffect(() => {
        // if (Boolean(selectedScene.input?.params?.src) === false) {
        //     setShowInputAdd(true);
        // }
        if (selectedScene.input?.params?.use_nobg) {
            setTheSrc(selectedScene.input.params.src_nobg);
        } else {
            setTheSrc(selectedScene.input?.params?.src);
        }
    }, [selectedScene.input]);

    useEffect(() => {
        if (theSrc) {
            handleInput({ opr: "save", input: selectedScene.input });
        }
    }, [theSrc]);

    const handleInput = async ({ opr, input, media }) => {
        switch (opr) {
            case "save":
                const update_res = await updateScene(selectedScene.id, {
                    input: JSON.stringify({ ...input }),
                });

                console.log("input update_res :: ", update_res);
                break;
            case "select":
                setSelectedDetail(selectedDetail?.id === input.id ? null : { ...input });
                break;

            default:
                break;
        }
    };

    return (
        <div className="scene-input w-full flex flex-col gap-2 p-6 pb-0 items-center">
            {theSrc && (
                <div
                    className={`relative overflow-hidden rounded-xl w-full h-52 bg-black ${
                        selectedDetail?.id === selectedScene.input.id ? "border-2 border-blue" : ""
                    }`}
                    onClick={() => {
                        handleInput({ opr: "select", input: selectedScene.input });
                    }}
                >
                    {selectedScene?.input?.params?.type?.includes("image") ? (
                        <img src={theSrc} alt="input" className="w-full h-full object-contain" />
                    ) : (
                        <VideoPlayer src={theSrc} fluid={true} hoverPlay={true} actions={false} />
                    )}

                    <div className="absolute bottom-0 right-0 p-2 flex items-center gap-2">
                        <button
                            className="button-white button-small py-1 px-3 rounded-full text-xs"
                            onClick={() => {
                                setShowInputAdd(true);
                            }}
                        >
                            change
                        </button>
                    </div>
                </div>
            )}
            {!theSrc && (
                <div
                    className="transition w-full h-52 scene-step-add flex flex-col items-center justify-center gap-2 p-3 group border border-dashed border-blue bg-blue/10 text-blue rounded-xl cursor-pointer"
                    onClick={() => {
                        setShowInputAdd(true);
                        //document.getElementById("mediaInput").click();
                    }}
                >
                    <div className="scene-step-action w-6 flex items-center items-center justify-center text-lg">+</div>
                    <div className="scene-step-add-type">Add Media</div>
                    {/* <input
                        type="file"
                        id="mediaInput"
                        className="hidden"
                        accept="image/*,video/*"
                        onChange={(e) => {
                            handleInput({ opr: "add", media: e.target.files[0] });
                        }}
                    /> */}
                </div>
            )}
        </div>
    );
};

export default Input;
