import { useState, useRef, useEffect } from "react";
import { GrUploadOption } from "react-icons/gr";
import VideoPlayer from "src/common/components/video-player/video-player";
import MediaSelect from "./media-select";
import Popup from "src/common/components/popup/popup";
import Knob from "src/common/components/form/knob";

const Media = (props) => {
    const fileInputRef = useRef(null);
    const { mediaType, onSelect, valueObj, selectedDetail } = props;
    const [showMediaSelect, setShowMediaSelect] = useState(false);

    const UploadFile = () => {
        return (
            <div
                className="transition min-h-52 max-h-52 min-w-full flex-1 flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-2xl cursor-pointer hover:border-blue bg-blue/10 text-black/30 hover:text-blue items-center justify-center"
                onClick={() => {
                    //fileInputRef.current?.click();
                    setShowMediaSelect(true);
                }}
            >
                <button className="relative z-10 button-white p-2 pr-5 rounded-3xl cursor-pointer flex items-center gap-2 capitalize">
                    <GrUploadOption className="text-md" />
                    {/* Upload {type} */}
                    Add Media
                </button>
            </div>
        );
    };

    useEffect(() => {
        if (props.showMediaSelect === true) {
            setShowMediaSelect(true);
        }
    }, [props.showMediaSelect]);

    const handleMediaSelect = (input) => {
        setShowMediaSelect(false);

        //NORMALIZE INPUT

        if (selectedDetail?.action === "generate-video") {
            const { videourl, videoid, imageurl, imageid, audiourl, voiceid, usevoice, file, text, name, result } = input;

            Object.assign(valueObj, {
                ...(videourl && { videourl, src: videourl }),
                ...(videoid && { videoid, src: result }),
                ...(imageurl && { imageurl, src: imageurl }),
                ...(imageid && { imageid, src: result }),
                ...(audiourl && { audiourl }),
                ...(voiceid && { voiceid }),
                ...(usevoice && { usevoice }),
                ...(file && { file, src: URL.createObjectURL(file) }),
                ...(text && { text }),
                ...(name && { name }),
            });
        } else {
            if (input.result) {
                valueObj.src = input.result;
            } else if (input.url) {
                valueObj.src = input.url;
            } else if (input.constructor.name === "File") {
                valueObj.src = URL.createObjectURL(input);
                valueObj.file = input;
            }
        }

        onSelect(valueObj);
    };

    return (
        <>
            {showMediaSelect && (
                <Popup
                    className="!w-full md:!w-7xl"
                    OnHide={() => {
                        setShowMediaSelect(false);
                    }}
                >
                    <MediaSelect
                        type={mediaType}
                        OnSelect={(input) => {
                            handleMediaSelect(input);

                            // handleMediaSelect(input);
                        }}
                    />
                </Popup>
            )}

            <input
                type="file"
                title=""
                accept={mediaType ? `${mediaType}/*` : "video/*,image/*,audio/*"}
                className="hidden"
                ref={fileInputRef}
                onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        valueObj = { src: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] };
                    }
                }}
            />
            {(valueObj?.src === "null" || !valueObj?.src) && <UploadFile />}

            {valueObj?.src !== "null" && valueObj?.src && (
                <div className="flex flex-col gap-2">
                    <div className="bg-black rounded-2xl w-full h-52 relative overflow-hidden border border-lightblue100">
                        {mediaType === "image" && <img src={valueObj.src} alt="uploaded image" className="w-full h-full object-contain" />}
                        {mediaType === "video" && <VideoPlayer src={valueObj.src} fluid={true} hoverPlay={true} actions={false} fit={`contain`} />}

                        {selectedDetail?.label !== "Generated Video" && (
                            <div className="absolute bottom-0 right-0 p-2 flex items-center gap-2">
                                <button
                                    className="button-white button-small py-1 px-3 rounded-full text-xs"
                                    onClick={() => {
                                        //fileInputRef.current?.click();
                                        setShowMediaSelect(true);
                                    }}
                                >
                                    change
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Media;
