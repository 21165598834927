import StepClass from "../step/class-step";
import axios from "axios";
export const scenesTemplates = (project) => {
    return [
        {
            name: "Blank",
            description: "A blank scene with no steps",
            steps: [],
        },

        {
            name: "Video",
            description: "A scene with a video",
            steps: [StepClass.overlayVideo()],
        },

        {
            name: "Image to Video",
            description: "A scene with an image converted to a video",
            steps: [StepClass.image2video()],
        },

        {
            name: "Zoom in",
            description: "A scene with a zoom in effect",
            steps: [StepClass.overlayVideo(), StepClass.zoom()],
        },

        {
            name: "Sliding Image",
            description: "A scene with a sliding left or right effect",
            steps: [StepClass.slide()],
        },

        {
            name: "Real Estate First Page",
            description: "A scene with a real estate first page",
            is_enabled: () => {
                if (project.proportion === "9:16") {
                    return true;
                }
                return false;
            },
            steps: [StepClass.realestateFirstPage()],
        },

        {
            name: "Real Estate Last Page",
            description: "A scene with a real estate last page",
            is_enabled: () => {
                if (project.proportion === "9:16") {
                    return true;
                }
                return false;
            },
            steps: [StepClass.realestateLastPage()],
        },
    ];
};

export const sequencesTemplates = (project, thedata) => {
    const data = thedata?.data;
    return [
        {
            name: "Real Estate Sequence",
            description: "A sequence with a real estate first and last page",
            sequence: [
                {
                    name: "First Page",
                    steps: [
                        StepClass.realestateFirstPage({
                            src: data?.images?.[0]?.src,

                            price: data?.price,
                            address: data?.address,
                            beds: data?.beds,
                            baths: data?.baths,
                            sqft: data?.sqft,
                        }),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.image2video({
                            src: data?.images?.[1]?.src,
                        }),
                    ],
                },
                {
                    name: "Page",
                    steps: [
                        StepClass.image2video({
                            src: data?.images?.[2]?.src,
                        }),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.image2video({
                            src: data?.images?.[3]?.src,
                        }),
                    ],
                },

                {
                    name: "Last Page",
                    steps: [
                        StepClass.realestateLastPage({
                            src: data?.images?.[4]?.src,
                        }),
                    ],
                },
            ],
        },

        {
            name: "Real Estate Sequence (Slide)",
            description: "A sequence with a real estate first and last page",
            sequence: [
                {
                    name: "First Page",
                    steps: [
                        StepClass.realestateFirstPage({
                            src: data?.images?.[0]?.src,

                            price: data?.price,
                            address: data?.address,
                            beds: data?.beds,
                            baths: data?.baths,
                            sqft: data?.sqft,
                        }),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.slide({
                            src: data?.images?.[1]?.src,
                        }),
                    ],
                },
                {
                    name: "Page",
                    steps: [
                        StepClass.slide({
                            option: "right",
                            src: data?.images?.[2]?.src,
                        }),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.slide({
                            src: data?.images?.[3]?.src,
                        }),
                    ],
                },

                {
                    name: "Last Page",
                    steps: [
                        StepClass.realestateLastPage({
                            src: data?.images?.[4]?.src,
                        }),
                    ],
                },
            ],
        },

        {
            name: "Real Estate Sequence (Zoom)",
            description: "A sequence with a real estate first and last page",
            sequence: [
                {
                    name: "First Page",
                    steps: [
                        StepClass.realestateFirstPage({
                            src: data?.images?.[0]?.src,

                            price: data?.price,
                            address: data?.address,
                            beds: data?.beds,
                            baths: data?.baths,
                            sqft: data?.sqft,
                        }),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.overlayVideo({
                            src: data?.images?.[1]?.src,
                        }),
                        StepClass.zoom(),
                    ],
                },
                {
                    name: "Page",
                    steps: [
                        StepClass.overlayVideo({
                            src: data?.images?.[2]?.src,
                        }),
                        StepClass.zoom(),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.overlayVideo({
                            src: data?.images?.[3]?.src,
                        }),
                        StepClass.zoom(),
                    ],
                },

                {
                    name: "Last Page",
                    steps: [
                        StepClass.realestateLastPage({
                            src: data?.images?.[4]?.src,
                        }),
                    ],
                },
            ],
        },


        {
            name: "Real Estate Sequence (Subtitles)",
            description: "A sequence with a real estate first and last page",
            sequence: [
                {
                    name: "First Page",
                    steps: [
                        StepClass.realestateFirstPage({
                            src: data?.images?.[0]?.src,

                            price: data?.price,
                            address: data?.address,
                            beds: data?.beds,
                            baths: data?.baths,
                            sqft: data?.sqft,
                        }),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.image2video({
                            src: data?.images?.[1]?.src,
                        }),
                        StepClass.avatar(),
                        StepClass.subtitles(),
                    ],
                },
                {
                    name: "Page",
                    steps: [
                        StepClass.image2video({
                            src: data?.images?.[2]?.src,
                        }),
                        StepClass.avatar(),
                        StepClass.subtitles(),
                    ],
                },

                {
                    name: "Page",
                    steps: [
                        StepClass.image2video({
                            src: data?.images?.[3]?.src,
                        }),
                        StepClass.avatar(),
                        StepClass.subtitles(),
                    ],
                },

                {
                    name: "Last Page",
                    steps: [
                        StepClass.realestateLastPage({
                            src: data?.images?.[4]?.src,
                        }),
                    ],
                },
            ],
        }
    ];
};
