import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleResponse, handleRes, handleErr } from "./response";
import { v4 as uuidv4 } from "uuid";
import { getS3 } from "./OLD/s3";
//QUERIES

export const imageMaker = async (url) => {
    if (getStoredUser() && getStoredUser().id) {
        let query = {
            Params: {
                userid: getStoredUser().id,
                auth: getStoredUser().token,
                sys: "imagemaker",
                act: "resize",
                url: url,
            },
        };
        try {
            const res = await axios.post(endpoint.rest, query);
            return res?.data[0];
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const upload = async ({ file, networkid = "", channelid = "", network = "", channel = "", postprocess }) => {
    const token = getStoredUser()?.token;

    const formData = new FormData();
    formData.append("userid", getStoredUser()?.id);
    formData.append("uploadfile", file);
    formData.append("networkid", networkid);
    formData.append("channelid", channelid);
    formData.append("network", network);
    formData.append("channel", channel);

    // Append file type if network matches specific cases
    if (["inputvideo", "inputimage", "inputaudio"].includes(network)) {
        formData.append("filetype", network);
    }

    // Append post-process option if provided
    if (postprocess) {
        formData.append("postprocess", postprocess);
    }

    const config = {
        method: "post",
        url: `${endpoint.upload}`,
        data: formData,
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const addToQueue = async ({ key, value, name, useAdminAuth }) => {
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.token,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }
    if (theAuthUser.id && theAuthUser.auth) {
        try {
            let query = {
                Params: {
                    userid: theAuthUser.id,
                    auth: theAuthUser.auth,
                    sys: "queue",
                    act: "add",
                    pagesize: "1",
                },
                Values: {
                    // userid: "11111111-1111-1111-1111-111111111111",
                    userid: theAuthUser.id,
                    key: key,
                    value: value,
                },
            };

            if (name) {
                query.Values.name = name;
            }
            // //console.log('addToQueue query:', query)
            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const getQueue = async (input, useAdminAuth) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "queue",
                act: "select",
            },
            Where: input,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const listQueue = async (input, pagenum) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            if (!pagenum) {
                pagenum = 1;
            }
            let query = {
                Params: {
                    userid: "11111111-1111-1111-1111-111111111111",
                    auth: "xxxx",
                    sys: "myqueue",
                    act: "select",
                    pagenumber: pagenum.toString(),
                    orderby: "createdate desc",
                },
                Where: input,
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const captureKeyframes = async (input, useAdminAuth) => {
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.token,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }

    if (theAuthUser.id && theAuthUser.auth) {
        try {
            let query = {
                Params: {
                    userid: theAuthUser.id,
                    auth: "xxxx",
                    sys: "crawl",
                    act: "keyframe",
                    pagesize: "1",
                },
                Values: input,
            };

            const res = await axios.post(endpoint.rest, query);
            let keyRes = handleResponse(query, res);
            //console.log("keyRes res : ", keyRes);
            if (keyRes && keyRes.dir) {
                const uploadRes = await uploadKeyframes(keyRes.dir, useAdminAuth);
                //console.log("upload keyframe res:", uploadRes);
                if (uploadRes && uploadRes.status && uploadRes.status.indexOf("Uploaded") !== -1) {
                    let thedir = keyRes.dir.split("tmp/")[1];
                    let getS3res = await getS3({ userid: theAuthUser.id, directory: `${thedir}` }, useAdminAuth);
                    //console.log("getS3res : ", getS3res);
                    return getS3res;
                } else {
                    return uploadRes;
                }
            }
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const uploadKeyframes = async (dir, useAdminAuth) => {
    const theAuthUser = {
        id: getStoredUser()?.id,
        auth: getStoredUser()?.token,
    };

    if (useAdminAuth === true) {
        theAuthUser.id = `11111111-1111-1111-1111-111111111111`;
        theAuthUser.auth = `xxxx`;
    }

    if (theAuthUser.id && theAuthUser.auth) {
        try {
            let query = {
                Params: {
                    userid: theAuthUser.id,
                    auth: "xxxx",
                    sys: "hls",
                    act: "uploaddir",
                    pagesize: "1",
                },
                Values: {
                    dir: `${dir}`,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const transcribe = async (url) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: "xxxx",
                    sys: "mediabot",
                    act: "transcribe",
                    pagesize: "1",
                },
                Values: {
                    url: `${url}`,
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const listLiveportraitQueues = async ({ pageNum }) => {
    if (getStoredUser() && getStoredUser().id) {
        try {
            let query = {
                Params: {
                    userid: getStoredUser().id,
                    auth: "xxxx",
                    sys: "myqueue",
                    act: "select",
                    pagenumber: pageNum.toString(),
                    pagesize: "20",
                    orderby: "createdate desc",
                },
                Where: {
                    userid: getStoredUser().id,
                    key: "liveportrait_test_ecs",
                },
            };

            const res = await axios.post(endpoint.rest, query);
            return handleResponse(query, res);
        } catch (error) {
            //console.log("error :", error);
        }
    }
};

export const getWorker = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}mediabot/worker/${id}`,

        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
