export const getRealVal = ({ stageSize, project, scaledX, scaledY, scaledWidth, scaledHeight }) => {
    const scaleX = stageSize.width / project.width;
    const scaleY = stageSize.height / project.height;

    const originalX = scaledX / scaleX;
    const originalY = scaledY / scaleY;

    const originalWidth = scaledWidth / scaleX;
    const originalHeight = scaledHeight / scaleY;

    return { x: originalX, y: originalY, width: originalWidth, height: originalHeight };
};

export const getScaledVal = ({ stageSize, project, originalX, originalY, originalWidth, originalHeight }) => {
    const scaleX = stageSize.width / project.width;
    const scaleY = stageSize.height / project.height;

    const scaledX = originalX * scaleX;
    const scaledY = originalY * scaleY;

    const scaledWidth = originalWidth * scaleX;
    const scaledHeight = originalHeight * scaleY;

    return { x: scaledX, y: scaledY, width: scaledWidth, height: scaledHeight };
};

export const pollForCompletion = async ({
    checkFn,
    onSuccess,
    onError,
    interval = 5000,
    maxAttempts = 60
}) => {
    let attempts = 0;
    
    return new Promise(async (resolve, reject) => {
        while (attempts < maxAttempts) {
            try {
                const result = await checkFn();
                
                if (result.success) {
                    const successResult = await onSuccess(result.data);
                    resolve(successResult);
                    return;
                } else if (result.error) {
                    await onError(result.error);
                    reject(new Error(result.error));
                    return;
                }
                
                attempts++;
                await new Promise(r => setTimeout(r, interval));
            } catch (error) {
                reject(error);
                return;
            }
        }
        reject(new Error("Polling timeout"));
    });
};

export const assColorToHex = (assColor) => {
    // Remove &H and & from the string if present
    const cleanColor = assColor.replace(/&H|&/g, '');
    
    // Handle both formats: AABBGGRR and BBGGRR
    const isLongFormat = cleanColor.length === 8;
    
    // Extract color components
    const alpha = isLongFormat ? cleanColor.substring(0, 2) : 'FF';
    const blue = cleanColor.substring(isLongFormat ? 2 : 0, isLongFormat ? 4 : 2);
    const green = cleanColor.substring(isLongFormat ? 4 : 2, isLongFormat ? 6 : 4);
    const red = cleanColor.substring(isLongFormat ? 6 : 4, isLongFormat ? 8 : 6);
    
    // Reassemble in HTML hex format (#RRGGBB or #RRGGBBAA)
    const hexColor = `#${red}${green}${blue}${alpha === 'FF' ? '' : alpha}`;
    
    return hexColor.toLowerCase();
};

export const hexToAssColor = (hexColor) => {
    // Remove the leading # if present
    const cleanHex = hexColor.replace('#', '');
    
    // Determine if the hex includes alpha
    const hasAlpha = cleanHex.length === 8;
    
    // Extract color components
    const red = cleanHex.substring(0, 2);
    const green = cleanHex.substring(2, 4);
    const blue = cleanHex.substring(4, 6);
    const alpha = hasAlpha ? cleanHex.substring(6, 8) : 'FF';
    
    // Reassemble in ASS color format (&H00FF00)
    const assColor = `&H${blue}${green}${red}`;
    
    return assColor.toUpperCase();
};

// Example usage:
// assColorToHex('&H0000FF&')     -> '#ff0000'
// assColorToHex('&HFF0000&')     -> '#0000ff'
// assColorToHex('&H7F0000FF&')   -> '#ff00007f'
// hexToAssColor('#ff0000')     -> '&H0000FF'
// hexToAssColor('#0000ff')     -> '&HFF0000'