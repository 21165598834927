import React, { useRef, useState, useEffect } from "react";
import { Group, Image, Text, Rect } from "react-konva";
import iconHouse from "src/common/assets/images/real-estate/icon-house.svg";
import ImageLayer from "./image";

const RealEstateFirstPageLayer = (props) => {
    const { detail, stageSize, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene, previewOnly } = props;
    const [logoImage, setLogoImage] = useState(null);
    const logoRef = useRef();
    const groupRef = useRef();

    useEffect(() => {
        // Load logo image
        const img = new window.Image();
        img.crossOrigin = "anonymous";
        img.src = detail.params.logo === "" ? iconHouse : detail.params.logo;
        img.onload = () => {
            setLogoImage(img);
        };
    }, [detail.params.logo]);

    useEffect(() => {
        if (logoImage) {
            const imgWidth = logoImage.width;
            const imgHeight = logoImage.height;
            const aspectRatio = imgWidth / imgHeight;

            // Set desired width
            const targetWidth = 70;
            // Calculate height based on aspect ratio
            const targetHeight = targetWidth / aspectRatio;
        }
    }, [logoImage]);

    return (
        <Group ref={groupRef}>
            {/* Background */}
            <Rect x={0} y={0} width={stageSize.width} height={stageSize.height} fill="black" />

            <ImageLayer
                detail={detail}
                previewOnly={true}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                selectedScene={selectedScene}
                setSelectedScene={setSelectedScene}
                stageSize={stageSize}
            />

            {/* Top Gradient */}
            <Rect
                x={0}
                y={0}
                width={stageSize.width}
                height={120}
                fillLinearGradientStartPoint={{ x: 0, y: 50 }}
                fillLinearGradientEndPoint={{ x: 0, y: 0 }}
                fillLinearGradientColorStops={[0, "rgba(0,0,0,0)", 1, "rgba(0,0,0,0.5)"]}
            />
            {/* Bottom Gradient */}
            <Rect
                x={0}
                y={stageSize.height - 120}
                width={stageSize.width}
                height={120}
                fillLinearGradientStartPoint={{ x: 0, y: 0 }}
                fillLinearGradientEndPoint={{ x: 0, y: 120 }}
                fillLinearGradientColorStops={[0, "rgba(0,0,0,0)", 1, "rgba(0,0,0,0.5)"]}
            />

            <Image ref={logoRef} image={logoImage} x={15} y={15} width={50} height={50 / (logoImage?.width / logoImage?.height || 1)} />

            {/* Price Text */}
            <Text
                x={15}
                y={stageSize.height - 95}
                text={`$${detail.params.price || "0"}`}
                fontSize={detail.params.fontSize || 34}
                fontStyle={"normal 500"}
                fontFamily={"Montserrat"}
                fill={detail.params.textColor || "#ffffff"}
                shadowColor={detail.params.textColor || "#000000"}
                shadowBlur={2}
                shadowOffsetX={2}
                shadowOffsetY={2}
                shadowOpacity={0.5}
            />

            {/* Property Details Text */}
            <Text
                x={15}
                y={stageSize.height - 50}
                text={`${detail.params.beds || "0"} Beds  ${detail.params.baths || "0"} Baths  ${detail.params.sqft || "0"} Sqft`}
                fontSize={detail.params.fontSize || 14}
                fontStyle={"normal 400"}
                fontFamily={"Montserrat"}
                fill={detail.params.textColor || "#ffffff"}
                shadowColor={detail.params.textColor || "#000000"}
                shadowBlur={2}
                shadowOffsetX={2}
                shadowOffsetY={2}
                shadowOpacity={0.5}
            />

            <Text
                x={15}
                y={stageSize.height - 30}
                text={detail.params.address || ""}
                fontSize={detail.params.fontSize || 14}
                fontStyle={"normal 400"}
                fontFamily={"Montserrat"}
                fill={detail.params.textColor || "#ffffff"}
                shadowColor={detail.params.textColor || "#000000"}
                shadowBlur={2}
                shadowOffsetX={2}
                shadowOffsetY={2}
                shadowOpacity={0.3}
            />
        </Group>
    );
};

export default RealEstateFirstPageLayer;
