import { useEffect } from "react";
import { TbBoxPadding } from "react-icons/tb";

import { PiAlignCenterHorizontalSimple } from "react-icons/pi";
import { TbBoxMargin } from "react-icons/tb";

const Fit = (props) => {
    const { selectedDetail, setSelectedDetail, stageSize } = props;

    const contain = () => {
        const imgWidth = selectedDetail.params.width;
        const imgHeight = selectedDetail.params.height;
        const stageRatio = stageSize.width / stageSize.height;
        const imgRatio = imgWidth / imgHeight;

        let newWidth, newHeight;

        if (imgRatio > stageRatio) {
            // Image is wider than the stage, scale based on width
            newWidth = stageSize.width;
            newHeight = (stageSize.width / imgWidth) * imgHeight;
        } else {
            // Image is taller than the stage, scale based on height
            newHeight = stageSize.height;
            newWidth = (stageSize.height / imgHeight) * imgWidth;
        }

        const newX = (stageSize.width - newWidth) / 2;
        const newY = (stageSize.height - newHeight) / 2;
        return { width: newWidth, height: newHeight, x: newX, y: newY };
    };

    const cover = () => {
        const imgWidth = selectedDetail.params.width;
        const imgHeight = selectedDetail.params.height;
        const stageRatio = stageSize.width / stageSize.height;
        const imgRatio = imgWidth / imgHeight;

        let newWidth, newHeight;

        // Cover the entire stage by scaling the image based on the smaller ratio (height or width)
        if (imgRatio > stageRatio) {
            // Image is wider, scale based on height
            newHeight = stageSize.height;
            newWidth = (stageSize.height / imgHeight) * imgWidth;
        } else {
            // Image is taller, scale based on width
            newWidth = stageSize.width;
            newHeight = (stageSize.width / imgWidth) * imgHeight;
        }

        // Center the image
        const newX = (stageSize.width - newWidth) / 2;
        const newY = (stageSize.height - newHeight) / 2;

        return { width: newWidth, height: newHeight, x: newX, y: newY };
    };

    const fits = [
        {
            id: "contain",
            icon: <TbBoxMargin className="text-xl" />,
            label: "contain",
            width: contain().width,
            height: contain().height,
            x: contain().x,
            y: contain().y,
        },
        {
            id: "cover",
            icon: <TbBoxPadding className="text-xl" />,
            label: "cover",
            width: cover().width,
            height: cover().height,
            x: cover().x,
            y: cover().y,
        },
    ];

    return (
        <div className=" flex flex-row gap-2 ">
            {fits.map((fit, a) => (
                <div
                    key={a}
                    className={`relative group transition flex cursor-pointer rounded-xl hover:bg-lightblue100/40 w-12 h-12 items-center justify-center flex border border-lightblue100`}
                    onClick={() => {
                        setSelectedDetail((prev) => ({
                            ...prev,
                            params: {
                                ...prev.params,
                                width: fit.width,
                                height: fit.height,
                                x: fit.x,
                                y: fit.y,
                            },
                        }));
                    }}
                >
                    {fit.icon}
                    <div className="absolute bg-white rounded-2xl -top-2/3 -translate-y-1 left-1/2 -translate-x-1/2 py-1 px-3 text-nowrap z-30 text-black text-xs shadow-xl border border-lightblue100 opacity-0 group-hover:opacity-100">
                        {fit.label}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Fit;
