import UiSelect from "src/modules/admin/studio/components/ui-select";
import RangeSlider from "./range-slider";
import UiDSection from "../../components/ui-dsection";
import { useEffect, useState } from "react";

const Option = ({ selectedScene, selectedDetail, setSelectedDetail }) => {
    let options = [];
    switch (true) {
        case selectedDetail.type === "transition":
            options = [
                "fade",
                "wipe-left",
                "wipe-right",
                "slide-left",
                "slide-right",
                "slide-up",
                "slide-down",
                "circle-crop",
                "rect-crop",
                "distance",
                "fade-black",
                "smooth-left",
                "smooth-right",
            ];
            break;

        case selectedDetail.action === "slide":
            options = ["left", "right"];
            break;
        case selectedDetail.action === "zoom":
            options = ["in", "out"];
            break;
        default:
            break;
    }

    const handleChange = (value) => {
        setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, option: value } }));
    };

    return (
        <UiDSection>
            <UiSelect options={options} value={selectedDetail.params.option} onChange={(val) => handleChange(val)} />
        </UiDSection>
    );
};

export default Option;
