import { v4 as uuidv4 } from "uuid";

export default class SceneClass {
    constructor(data = {}) {
        // Base properties
        this.tempId = data.tempId || uuidv4();
        this.index = data.index || "1";
        this.projectid = data.projectid || "";
        this.name = data.name || `Scene ${data.index}`;
        this.active = false;
        this.steps = data.steps || [];
        this.input = data.input || "{}";
        this.result =""
        this.state=""
        this.mode="edit"
        // this.result = data.result || {
        //     sceneid: null,
        //     queueid: null,
        //     src: null,
        //     mode: "edit",
        // };
        // this.background_base64 = data.background_base64 || null;
        // this.base64 = data.base64 || null;

        this.background = data.background || "#ffffff";
    }

    // {
    //     "active": "false",
    //     "background": "#ffffff",
    //     "id": "OB0BMUcaa32b8",
    //     "index": "2",
    //     "input": "{}",
    //     "name": "Scene 2",
    //     "parentid": "OAQ8SN3935bcd",
    //     "result": "",
    //     "state": "",
    //     "userid": "OA0BH400fcf71"
    // }
    
    getData() {
        return {
            tempId: this.tempId,
            index: this.index,
            projectid: this.projectid,
            name: this.name,
            active: this.active,
            steps: this.steps,
            input: this.input,
            result: this.result,
            state: this.state,
            mode: this.mode,
            // background_base64: this.background_base64,
            // base64: this.base64,
            background: this.background,
        };
    }

    addStep(step) {
        this.steps.push(step);
    }


    static create(data = {}) {
        const sceneDefaults = { 
            tempId: uuidv4(),
            name: data.name || `Scene ${data.index}`,
            index: data.index || "1",
            projectid: data.projectid || "",
        };

        return new SceneClass({
            ...sceneDefaults,
            ...data,
        });
    }
}
