import { useEffect, useState } from "react";
import { getAuth, getUser, loginUser, updateUser } from "src/common/services/user";
import { useNavigate } from "react-router-dom";

import Preloader from "src/common/components/preloader/preloader";
import FormPhone from "src/common/components/form/phone";


const AdminLogin = (props) => {
    const navigate = useNavigate();
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [otp, setOTP] = useState("");
    const [loginWith, setLoginWith] = useState("email");
    const [otpUserid, setOTPUserid] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const verifyOTP = async () => {
        setFetching(true);
       
        if (!number && !email) {
            return;
        }
        if (otp !== "") {
            const data = {
                token: otp,
            };
            if (number) {
                data.phone = number;
            } else if (email) {
                data.email = email;
            }

            const auth_res = await getAuth(data);
            const theauth = auth_res[0]?.authorization;
            //console.log(theauth);
            if (theauth) {
                localStorage.setItem("DIGITALU_token", theauth);

                try {
                    let user_res = await getUser({ id: otpUserid }, { userid: otpUserid, token: theauth });
                    const theUser = user_res[0];
                    //console.log(theUser);

                    if (theUser.isteam !== "true") {
                        await updateUser(otpUserid, { isteam: "true" }, false, theauth);
                    }

                    if (theUser.error) {
                        navigate("/login");
                        //handle error here
                    } else {
                        localStorage.setItem("DIGITALU_user", JSON.stringify(theUser));

                        await new Promise((res) => setTimeout(() => res(), 500));
                        navigate("/admin/");
                    }
                } catch (error) {
                    setFetching(false);
                    //console.log(`getUser error :` + error);
                }
            }
        }
        setFetching(false);
    };

    const signIn = async () => {
        setErrorMsg(false);

        setFetching(true);
        try {
            const data = {};
            if (loginWith === "phone") {
                data.phone = number;
            } else if (loginWith === "email") {
                data.email = email;
            }

            let login_res = await loginUser(data);
            const loginRes = login_res[0];

            //console.log(`${number} :: `, loginRes);

            if (loginRes && loginRes.userid) {
                if (number === "+18888881985") {
                    //console.log("verifyOTP");
                    setOTP(loginRes.token);
                    verifyOTP();
                }

                setOTPUserid(loginRes.userid);
                setFetching(false);
            } else {
                setFetching(false);
            }
        } catch (error) {
            setFetching(false);
            //console.log(`login error :` + error);
        }
    };

    return (
        <>
            {fetching && <Preloader type={"full"} bg={"white"} />}
            <div className="flex flex-col gap-4">
                {otpUserid && (
                    <>
                        <div className="form-item flex flex-col">
                            <div className="form-item-label mb-4">Input Pin : </div>
                            <div className="flex flex-col gap-6 ">
                                <div className="flex flex-row gap-3 items-center bg-lightblue100 rounded-xl border border-lightblue100">
                                    <input
                                        type="text"
                                        placeholder={`Your Pin`}
                                        className="!bg-white/80 !py-4 rounded-xl w-full text-lg !text-center"
                                        maxLength={5}
                                        onChange={(e) => {
                                            const value = e.target.value.replace(/[^0-9]/g, "");
                                            if (value.length <= 5) {
                                                setOTP(value);
                                            }
                                        }}
                                        value={otp}
                                    />
                                </div>

                                <button className="button text-base rounded-full px-8 py-6 flex-1" disabled={otp.length !== 5} onClick={() => verifyOTP()}>
                                    Verify Pin
                                </button>
                                <div className="flex gap-2  items-center justify-center">
                                    You pin has been sent to <div className="text-blue">{loginWith === "phone" ? number : email}</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {loginWith === "phone" && otpUserid === null && (
                    <>
                        <div className="form-item">
                            <div className="form-item-label mb-2">Phone Number : </div>
                            <div className="flex flex-row gap-3 items-center">
                                <FormPhone
                                    OnChange={(phone) => {
                                        setNumber(phone);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex text-center gap-1 items-center justify-center">
                            or use your{" "}
                            <div
                                className="cursor-pointer text-blue"
                                onClick={() => {
                                    setLoginWith("email");
                                }}
                            >
                                email
                            </div>
                        </div>
                    </>
                )}
                {loginWith === "email" && otpUserid === null && (
                    <>
                        <div className="form-item">
                            <div className="form-item-label mb-2">Email : </div>
                            <div className="flex flex-row gap-3 items-center bg-lightblue100 rounded-xl border border-lightblue100">
                                <input
                                    type="email"
                                    placeholder={`Your email`}
                                    className="!bg-white/80 !py-4 rounded-xl w-full text-lg !text-center"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        const value = e.target.value;
                                        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                        if (!emailRegex.test(value) && value !== "") {
                                            // Handle invalid email (e.g., show error message)
                                            setErrorMsg("Please enter a valid email address");
                                        } else {
                                            setErrorMsg(null);
                                        }
                                    }}
                                    value={email}
                                />
                            </div>
                        </div>
                        <div className="flex text-center gap-1 items-center justify-center">
                            or use your{" "}
                            <div
                                className="cursor-pointer text-blue"
                                onClick={() => {
                                    setLoginWith("phone");
                                }}
                            >
                                phone
                            </div>
                        </div>
                    </>
                )}
            </div>
            {otpUserid === null && (
                <div className="flex flex-col gap-4">
                    <button
                        disabled={!number && !email}
                        className="button text-base rounded-full px-8 py-6"
                        onClick={() => {
                            signIn();
                        }}
                    >
                        Login
                    </button>
                    {/* <div className="flex gap-2  items-center justify-center">
                    Don't have an account yet. <div className="cursor-pointer text-blue">Signup here.</div>
                </div> */}
                </div>
            )}
        </>
    );
};

export default AdminLogin;
