import { useSortable } from "@dnd-kit/sortable";
import { GoGrabber } from "react-icons/go";
import { LuMinusCircle } from "react-icons/lu";
import { formatStepType } from "src/modules/admin/studio/studio-funcs";
import { handleStep } from "../handlers/step-handler";
import { IoLayersOutline } from "react-icons/io5";

import { LuFunctionSquare } from "react-icons/lu";
import { PiMagicWand } from "react-icons/pi";
import { detectMediaType } from "../utils";

import { FiEye, FiEyeOff } from "react-icons/fi";

const Step = ({ step, selectedScene, setSelectedScene, selectedDetail, setSelectedDetail, showActions }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: step.id });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        zIndex: isDragging ? 1 : 0,
        boxShadow: isDragging ? "0 6px 12px rgba(111, 118, 187, 0.12)" : "none",
        backgroundColor: isDragging ? "#ffffff" : undefined,
    };

    if (step.active === undefined) {
        step.active = true;
    }

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`scene-step flex items-center gap-0 group cursor-pointer border-2 border-lightblue100 rounded-xl bg-white 
                ${isDragging ? "opacity-90" : "" }
                $
            ${selectedDetail?.id === step.id ? "!border-blue/80 !border-2 text-blue" : ""}
            `}
        >
         
            {showActions && (
                <div
                    className="transition scene-step-action w-10 h-10 pl-2  flex items-center items-center justify-center text-lg text-red-500/60 hover:text-red-500"
                    onClick={async () => {
                        await handleStep({ opr: "delete", step, selectedScene, setSelectedScene, selectedDetail, setSelectedDetail });
                    }}
                >
                    <LuMinusCircle className="text-base" />
                </div>
            )}
            <div className={`scene-step-type flex-1 h-full gap-0 p-2 relative overflow-hidden flex items-center capitalize ${selectedDetail?.id === step.id ? "!border-blue" : ""}`}>
                <div
                    className="step-name flex-1 flex items-center gap-2"
                    onClick={async () => {
                        await handleStep({ opr: "select", step, selectedScene, setSelectedScene, selectedDetail, setSelectedDetail });
                    }}
                >
                    <div className="w-10 h-10 relative overflow-hidden rounded-lg">
                        {(() => {
                            const mediaType = detectMediaType({ url: step.params.src });
                            switch (mediaType) {
                                case "video":
                                    return <video src={step.params.src} className="w-full h-full object-cover" muted />;

                                case "image":
                                    return <img src={step.params.src} className="w-full h-full object-cover" />;

                                default:
                                    if (
                                        (step.type === "video" || step.type === "image" || step.type === "base64") &&
                                        step.type !== "effect" &&
                                        step.action !== "subtitles" &&
                                        step.action !== "realestate-first-page" &&
                                        step.action !== "realestate-last-page"
                                    ) {
                                        return (
                                            <div className="w-full h-full flex items-center justify-center">
                                                <IoLayersOutline className="w-6 h-6 text-blue" />
                                            </div>
                                        );
                                    } else if (step.type === "effect") {
                                        return (
                                            <div className="w-full h-full flex items-center justify-center">
                                                <PiMagicWand className="w-6 h-6 text-blue" />
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className="w-full h-full flex items-center justify-center">
                                                <LuFunctionSquare className="w-6 h-6 text-blue" />
                                            </div>
                                        );
                                    }
                            }
                        })()}
                        <div className="absolute top-0 left-0 w-full h-full bg-indigo-400/10"></div>
                    </div>
                    {step.label ? step.label : step.name ? step.name : formatStepType(step.action)}
                </div>
                {!step.active && <div className="absolute top-0 left-0 w-full h-full bg-white/70"></div>}
                <div
                    className="flex items-center p-2 relative z-20"
                    onClick={async () => {
                        await handleStep({ opr: "toggleActive", step, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene });
                    }}
                >
                    {step.active ? <FiEye className="w-4 h-4" /> : <FiEyeOff className="w-4 h-4" />}
                </div>
                {/* <div className="text-xs text-black/50">{step.type !== step?.name?.toLowerCase() ? step.type : ""}</div> */}
            </div>
            {showActions && (
                <div className="scene-step-sort cursor-grab py-2 pr-2" {...attributes} {...listeners}>
                    <GoGrabber className="text-xl" />
                </div>
            )}
        </div>
    );
};

export default Step;
