import React, { useRef, useState, useEffect } from "react";
import { Group, Image } from "react-konva";
import Konva from "konva";

const MergeLayer = (props) => {
    const { scenes, stageSize } = props;
    const [images, setImages] = useState([]);
    const [dimensions, setDimensions] = useState([]);
    const videoRefs = useRef([]);
    const imageRefs = useRef([]);

    useEffect(() => {
       
        if (!scenes) return;
       
        // Create video elements for each scene
        const videos = scenes.map((sceneSrc, index) => {
            const video = document.createElement("video");
            video.src = sceneSrc;
            video.crossOrigin = "anonymous";
            video.loop = true;
            video.muted = true;
            video.playsInline = true;
            video.volume = 1.0;
            video.preload = "auto";
            video.currentTime = 0.01;

            videoRefs.current[index] = video;
            
            return new Promise((resolve) => {
                video.addEventListener("loadedmetadata", () => {
                    if (video.videoWidth && video.videoHeight) {
                       
                        resolve(video);
                    }
                });
            });
        });

        // Wait for all videos to load
        Promise.all(videos).then((loadedVideos) => {
            setImages(loadedVideos);
        });

        return () => {
            videoRefs.current.forEach((video) => {
                if (video) {
                    video.pause();
                    video.src = "";
                }
            });
            videoRefs.current = [];
        };
    }, [scenes]);

    useEffect(() => {
        images.forEach((image, index) => {
            if (image && imageRefs.current[index]) {
                const anim = new Konva.Animation(() => {
                    imageRefs.current[index]?.getLayer()?.batchDraw();
                    return true;
                }, imageRefs.current[index]?.getLayer());

                anim.start();

                return () => {
                    anim.stop();
                };
            }
        });
    }, [images]);

    useEffect(() => {
        if (images.length > 0) {
            const offset = 20; // pixels to offset each image
            const scale = 0.8; // zoom factor (less than 1 to zoom out)
            const maxImages = 3; // maximum number of images to show
            const reversedImages = [...images].reverse().slice(0, maxImages); // Take only last 3 images

            const newDimensions = reversedImages.map((image, index) => {
                const videoWidth = image.videoWidth;
                const videoHeight = image.videoHeight;
                const stageRatio = stageSize.width / stageSize.height;
                const videoRatio = videoWidth / videoHeight;

                let newWidth, newHeight, newX, newY;

                if (videoRatio > stageRatio) {
                    newWidth = stageSize.width * scale;
                    newHeight = (stageSize.width / videoWidth) * videoHeight * scale;
                } else {
                    newHeight = stageSize.height * scale;
                    newWidth = (stageSize.height / videoHeight) * videoWidth * scale;
                }

                // Center the cascading effect by calculating total offset spread
                const totalOffsetX = (Math.min(images.length, maxImages) - 1) * offset;
                const totalOffsetY = (Math.min(images.length, maxImages) - 1) * offset;

                newX = (stageSize.width - newWidth - totalOffsetX) / 2 + index * offset;
                newY = (stageSize.height - newHeight - totalOffsetY) / 2 + index * offset;

                return {
                    width: newWidth,
                    height: newHeight,
                    x: newX,
                    y: newY,
                    opacity: 1,
                    shadowColor: "rgba(0,0,0,0.3)",
                    shadowBlur: 10,
                    shadowOffset: { x: 5, y: 5 },
                    shadowOpacity: 0.5,
                    cornerRadius: 10,
                };
            });

            setDimensions(newDimensions);
        }
    }, [images, stageSize]);

    return (
        <Group>
            {images
                .slice()
                .reverse()
                .slice(0, 3)
                .map(
                    (image, index) =>
                        dimensions[index] && (
                            <Image
                                key={index}
                                image={image}
                                {...dimensions[index]}
                                ref={(el) => (imageRefs.current[index] = el)}
                                draggable={false}
                                shadowEnabled={true}
                            />
                        )
                )}
        </Group>
    );
};

export default MergeLayer;
