import { chain } from "src/common/services/studio";
import { upload } from "src/common/services/utils";
import { getVideo } from "src/common/services/generate";

export const generateScene = async ({ project, scene, stageSize }) => {
    const sampleOverlayData = {
        canvas: {
            width: 1280,
            height: 720,
        },
        st: [
            {
                index: 1,
                action: "overlay-video",
                type: "video",
                params: {
                    src: "https://svc.staging.squadz.live/download/OA0BH400fcf71/OA0BH400fcf71/OAACBB7b63711/bella_drive_square2.mp4",
                    x: 0,
                    y: 0,
                    width: 1280,
                    height: 720,
                },
            },
            {
                index: 3,
                action: "zoom",
                type: "effect",
                params: {
                    option: "out",
                    duration: 1,
                },
            },
        ],
    };

    try {
        const overlayData = {
            canvas: {
                width: parseInt(project.width),
                height: parseInt(project.height),
            },

            steps: [],
        };
   

        if (scene.background_base64 && scene.steps.find((step) => step.action === "image2video") === undefined) {
            const theBackgroundLayer = {
                index: 1,
                action: "overlay-image",
                type: "base64",
                params: {
                    src: scene.background_base64,
                    x: 0,
                    y: 0,
                    width: parseInt(project.width),
                    height: parseInt(project.height),
                },
            };
            overlayData.steps = [...overlayData.steps, theBackgroundLayer];
        }

        const input_real_val = getRealVal({
            stageSize,
            project,
            scaledX: scene.input?.params.x,
            scaledY: scene.input?.params.y,
            scaledWidth: scene.input?.params.width,
            scaledHeight: scene.input?.params.height,
        });

        if (scene.input?.params?.file) {
            try {
                const thefile = scene.input.params.file;
                const input_upload = await upload({
                    file: thefile,
                    network: `${thefile.type.split("/")[0]}s`,
                    channel: `##input${thefile.type.split("/")[0]}s`,
                });
                console.log("input_upload :: ", input_upload);
                scene.input.params.src = input_upload[0].cache;
            } catch (error) {
                console.error("Failed to process input file:", error);
            }
        }

        //CREATE INPUT LAYER
        if (Boolean(scene.input)) {
            const theinputlayer = {
                index: 2,
                action: "overlay-video",
                type: "video",
                params: {
                    src: `${scene.input.params.src}`,
                    x: Math.round(input_real_val.x),
                    y: Math.round(input_real_val.y),
                    width: Math.round(input_real_val.width),
                    height: Math.round(input_real_val.height),
                },
            };

            overlayData.steps = [...overlayData.steps, theinputlayer];
        }

        //LOOP THROUGH STEPS
        for (let i = 0; i < scene.steps.length; i++) {
            const step = scene.steps[i];
            const sindex = i;

            if (step.params?.file || step.params?.mediaObj?.constructor.name === "File") {
                try {
                    const upload_res = await upload({ file: step.params?.file || step.params?.mediaObj });
                    step.params.src = upload_res[0].cache;
                } catch (error) {
                    console.error("Failed to process image file:", error);
                }
            }
            const step_real_val = getRealVal({
                stageSize,
                project,
                scaledX: step.params?.x,
                scaledY: step.params?.y,
                scaledWidth: step.params?.width,
                scaledHeight: step.params?.height,
            });

            const real_x = step.params?.x ? step_real_val.x : input_real_val.x;
            const real_y = step.params?.y ? step_real_val.y : input_real_val.y;
            const real_width = step.params?.width ? step_real_val.width : input_real_val.width;
            const real_height = step.params?.height ? step_real_val.height : input_real_val.height;

            const thestep = {
                index: overlayData.steps.length + 1,
                action: step.action,
                type: step.type,
                params: {},
            };

            const paramKeys = [
                "src",
                "name",

                "x",
                "y",
                "width",
                "height",
                "rotation",

                "genVidId",

                "option",

                "duration",
                "strength",

                "scenes",

                "volume",

                "position",

                "text",
                "font_family",
                "font_size",
                "font_color",
                "font_style",
                "font_weight",
                "font_align",

                "highlight",
            ];

            paramKeys.forEach((key) => {
                if (step.params?.[key] !== undefined) {
                    thestep.params[key] = step.params[key];

                    switch (key) {
                        case "src":
                            let thesrc = step.params.src;
                            console.log(step.action, sindex);
                            if (sindex === 0 && step.action !== "overlay-image" && step.action !== "overlay-video") {
                                thesrc = scene.input.params.src;
                            }

                            thestep.params.src = thesrc;
                            break;
                        case "x":
                            thestep.params.x = Math.round(real_x);
                            break;
                        case "y":
                            thestep.params.y = Math.round(real_y);
                            break;
                        case "width":
                            thestep.params.width = Math.round(real_width);
                            break;
                        case "height":
                            thestep.params.height = Math.round(real_height);
                            break;
                        case "position":
                            thestep.params.position = step.params.position.label;
                            break;
                        case "volume":
                            thestep.params.volume = parseFloat(step.params.volume);
                            break;
                    }
                }
            });

            if (step.action === "subtitles") {
                thestep.params.highlight = (step.params.highlight ?? false).toString();
                thestep.params.x = Math.round(input_real_val.x);
                thestep.params.y = Math.round(input_real_val.y);
                thestep.params.width = Math.round(input_real_val.width);
                thestep.params.height = Math.round(input_real_val.height);
            }

            if (step.action === "image2video") {
                thestep.params.src = scene.input.params.src;
                thestep.params.x = Math.round(input_real_val.x);
                thestep.params.y = Math.round(input_real_val.y);
                thestep.params.width = Math.round(input_real_val.width);
                thestep.params.height = Math.round(input_real_val.height);
            }

            if (step.action === "generate-video") {
                thestep.params.x = Math.round(input_real_val.x);
                thestep.params.y = Math.round(input_real_val.y);
                thestep.params.width = Math.round(input_real_val.width);
                thestep.params.height = Math.round(input_real_val.height);

                //PROCESS MEDIA OBJECT
                if (step.params.mediaObj) {
                    console.log('12::',step.params.src)
                    if (step.params.mediaObj.constructor.name === "File") {
                        if (step.params.mediaObj.type.split("/")[0] === "video") {
                            thestep.params.videourl = step.params.src;
                        } else if (step.params.mediaObj.type.split("/")[0] === "image") {
                            thestep.params.imageurl = step.params.src;
                        }
                    } else if (step.params.mediaObj.type) {
                        thestep.params[`${step.params.mediaObj.type}id`] = step.params.mediaObj.id;
                    }
                }

                //PROCESS VOICE OBJECT
                if (step.params.voiceObj && step.params.voiceObj.voiceid) {
                    thestep.params.usevoice = "fromId";
                    thestep.params.voiceid = step.params.voiceObj.voiceid;
                } else if (step.params.voiceObj && step.params.voiceObj.constructor.name === "File") {
                    thestep.params.usevoice = "direct";
                    thestep.params.audiourl = step.params.src;
                    delete thestep.params.text;
                }
            }

            if (step.action === "overlay-text") {
                thestep.type = "base64";
                thestep.params.src = step.base64;
                thestep.params.x = Math.round(step_real_val.x);
                thestep.params.y = Math.round(step_real_val.y);
                thestep.params.width = Math.round(step_real_val.width);
                thestep.params.height = Math.round(step_real_val.height);
            }

            overlayData.steps = [...overlayData.steps, thestep];
        }

        console.log("generateScene input :: ", overlayData);
        // const overlay_res = await chain(project.id, scene.id, overlayData);
        // console.log("generateScene output :: ", overlay_res);

       //return overlay_res;
    } catch (error) {
        console.log(error);
    }
};

export const handleToEdit = ({ toEdit, setTheVideo, stageSize, selScreen }) => {
    const thebg = toEdit.layers[0];
    const theavatar = toEdit.layers.find((layer) => layer.avatarid);
    const theTexts = toEdit.layers.find((layer) => layer.elements);
    const theAudio = toEdit.layers.find((layer) => layer.type === "audio");

    //console.log(theavatar);
    const avatar_scaled = getScaledVal({
        stageSize,
        selScreen,
        originalX: theavatar.x,
        originalY: theavatar.y,
        originalWidth: theavatar.width,
        originalHeight: theavatar.height,
    });

    getVideo({ id: theavatar.avatarid })
        .then((res) => {
            //console.log("1 :: ", theavatar);
            setTheVideo({
                avatar: {
                    video: res[0],
                    effects: [
                        {
                            type: "nobg",
                            value: theavatar.src.indexOf("nobg.mp4") !== -1 ? true : false,
                        },
                        {
                            type: "enhance",
                            value: theavatar.src.indexOf("upscaled.mp4") !== -1 ? true : false,
                        },
                    ],
                    x: theavatar.x,
                    y: theavatar.y,
                    height: theavatar.height,
                    width: theavatar.width,
                },
            });
        })
        .catch((error) => {
            //console.log(error);
        });
};

export const generateProject = async (project) => {
    const overlayData = {
        canvas: {
            width: project.width,
            height: project.height,
        },

        layers: [],
    };

    project.scenes.map(async (scene) => {
        if (scene.active && scene.result.url) {
            const thescenelayer = {
                index: overlayData.layers.length,
                type: "video",
                src: scene.result.url,
                x: 0,
                y: 0,
                width: project.width,
                height: project.height,
            };
            overlayData.layers = [...overlayData.layers, thescenelayer];
        }
    });
    try {
        console.log("generateProject input :: ", overlayData);
        //const overlay_res = await overlayer(sampleOverlayData);
        const overlay_res = await chain(overlayData);
        console.log("generateProject output :: ", overlay_res);
    } catch (error) {
        console.error("Failed to generate project:", error);
    }
};

///FUNCS

export const getRealVal = ({ stageSize, project, scaledX, scaledY, scaledWidth, scaledHeight }) => {
    const scaleX = stageSize.width / project.width;
    const scaleY = stageSize.height / project.height;

    const originalX = scaledX / scaleX;
    const originalY = scaledY / scaleY;

    const originalWidth = scaledWidth / scaleX; // 100 / 0.78125 = 128
    const originalHeight = scaledHeight / scaleY; // 50 / 0.694444 = 72

    return { x: originalX, y: originalY, width: originalWidth, height: originalHeight };
};

export const getScaledVal = ({ stageSize, selScreen, originalX, originalY, originalWidth, originalHeight }) => {
    const scaleX = stageSize.width / selScreen.width;
    const scaleY = stageSize.height / selScreen.height;

    const scaledX = originalX * scaleX;
    const scaledY = originalY * scaleY;

    const scaledWidth = originalWidth * scaleX;
    const scaledHeight = originalHeight * scaleY;

    return { x: scaledX, y: scaledY, width: scaledWidth, height: scaledHeight };
};

export const formatStepType = (type) => {
    if (!type) return "";
    return type.includes("-")
        ? type
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
        : type;
};
