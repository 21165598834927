import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";

////SCENES

export const listScenes = async ({ projectid, pageNum }) => {
    const token = getStoredUser()?.token;
    pageNum = pageNum || 1;
    const config = {
        method: "get",
        url: `${endpoint.rest}scene/all/${projectid}?pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const createScene = async (scene) => {

    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}scene/create`,
        data: scene,

        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const updateScene = async (id, scene) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}scene/${id}`,
        data: scene,

        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const deleteScene = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "delete",
        url: `${endpoint.rest}scene/${id}`,
        // data: { projectid },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getScene = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}scene/${id}`,
        // data: { projectid },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};



export const generateScene = async (projectid, sceneid) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/${projectid}/${sceneid}`,
        data: {},
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
}

export const reorderScene = async (scenes) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}reorder/scene`,
        data: {
            value: JSON.stringify(scenes),
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
