export const detectMediaType = ({detail, url}) => {
    if (!url) return null;
    
    // Check if it's a blob URL
    if (url.startsWith('blob:')) {

        let mtype = 'video';

        if(detail?.params?.file?.constructor?.name === 'File'){
            mtype = detail?.params?.file?.type?.split('/')[0]; 
        }
        // For blob URLs, we can try to detect type from the mime type
        // But since we don't have direct access to the mime type,
        // we'll need to rely on the file extension in the original filename
        // or assume it's a video (you may want to adjust this based on your use case)
        return mtype;
    }
    
    // Get the file extension from the URL
    const extension = url.split('.').pop().toLowerCase();
    
    // Common image extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
    // Common video extensions
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov'];
    
    if (imageExtensions.includes(extension)) return 'image';
    if (videoExtensions.includes(extension)) return 'video';
    
    return null;
};
