import UiSelect from "../components/ui-select";

const ArtboardLayout = (props) => {
    const { screens, project, setProject } = props;

    const getSizeOptions = (proportion) => {
        switch (proportion) {
            case "16:9":
                return [
                    { value: { width: "1280", height: "720" }, label: "1280 x 720" },
                    { value: { width: "1920", height: "1080" }, label: "1920 x 1080" },
                    { value: { width: "2560", height: "1440" }, label: "2560 x 1440" },
                ];
            case "9:16":
                return [
                    { value: { width: "720", height: "1280" }, label: "720 x 1280" },
                    { value: { width: "1080", height: "1920" }, label: "1080 x 1920" },
                    { value: { width: "1440", height: "2560" }, label: "1440 x 2560" },
                ];
            case "4:3":
                return [
                    { value: { width: "800", height: "600" }, label: "800 x 600" },
                    { value: { width: "1024", height: "768" }, label: "1024 x 768" },
                    { value: { width: "1600", height: "1200" }, label: "1600 x 1200" },
                ];
            default:
                return [];
        }
    };

    // Get current size options based on selected proportion
    const sizeOptions = getSizeOptions(project.proportion);

    const handleProportionChange = (value) => {
        const defaultSize = getSizeOptions(value)[0]?.value || { width: "1280", height: "720" };
        setProject((prev) => ({
            ...prev,
            proportion: value,
            width: defaultSize.width,
            height: defaultSize.height,
        }));
    };

    return (
        <div className="artboard-layout flex flex-row items-center justify-between gap-2">
            <div className="flex-1 flex flex-row items-center justify-between  px-2 ">
                {screens.map((screen, index) => (
                    <div
                        key={index}
                        className={`flex items-center justify-center p-2 px-3 cursor-pointer hover:text-text/60 border-r border-lightblue100 last:border-r-0 ${project.proportion === screen.value ? "" : "text-black/30"}`}
                        onClick={() => handleProportionChange(screen.value)}
                        title={screen.label}
                    >
                        {screen.icon}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ArtboardLayout;
