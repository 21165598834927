import { useState, useEffect } from "react";
import { IoLayersOutline } from "react-icons/io5";
import { LuFunctionSquare } from "react-icons/lu";
import { PiMagicWand } from "react-icons/pi";
import StepClass from "./class-step";
import { listEffects } from "src/common/services/studio";
import { addStep } from "src/common/services/step";
import { v4 as uuidv4 } from "uuid";

const StepAdd = (props) => {
    const { project, selectedScene, setSelectedScene, setShowStepAdd, setSelectedDetail } = props;
    const [search, setSearch] = useState("");
    const [effectsLibrary, setEffectsLibrary] = useState([]);

    const functionsLibrary = [
        

        StepClass.init({
            name: "Add Subtitles",
            description: "Automatically add subtitles to your video",
            action: "subtitles",
            type: "video",
            params: {
                label: "Subtitles",
                src: "",
                //type: "video",
                // width: null,
                // height: null,

                highlight: "false",
               
                width: "null",
                height: "null",
                x: "null",
                y: "null",

                position: "bottom",
                font_family: "Montserrat",
             
                font_size: "16",
                font_color: "&Hffffff",

                font_style_bold: "-1",
                font_style_italic: "0",

                text_stroke_color: "&Hffffff",

                text_shadow_position: "2",
                text_shadow_color: "&H000000",
                // font_style: "normal 400",
                // text_shadow: "0 0 2px #111111",
                // text_stroke: "1px #ffffff",
            },
        }),


        StepClass.init({
            name: "Real Estate First Page",
            description: "Generate a real estate first page (only for mobile)",
            action: "realestate-first-page",
            is_enabled: () => {
                if (project.proportion === "9:16") {
                    return true;
                }
                return false;
            },
            type: "video",
            params: {
                label: "Real Estate First Page",
                src: "null",
                duration: "5",

              //  logo: "https://svc.staging.squadz.live/download/11111111-1111-1111-1111-111111111111/11111111-1111-1111-1111-111111111111/OB06MVe525e6c/logo_default.png",
                logo: "",
                price: "0",
                address: "Address Here",
                beds: "0",
                baths: "0",
                sqft: "0",

                // width:"null",
                // height:"null",
                // x:"null",
                // y:"null",
            },
        }),

        StepClass.init({
            name: "Real Estate Last Page",
            description: "Generate a real estate last page (only for mobile)",
            action: `realestate-last-page`,
            is_enabled: () => {
                if (project.proportion === "9:16") {
                    return true;
                }
                return false;
            },
            type: "video",
            params: {
                label: "Real Estate Last Page",
                src: "null",
                duration: "5",

                agent_name: "Agent Name",
                agent_email: "email@example.com",
                agent_contact: "000-000-0000",

                logo: "",
            },
        }),
        // StepClass.init({
        //     name: "Green Screen",
        //     description: "Remove green screen bg from your video",
        //     action: "avatar",
        //     type: "video",
        //     params: {
        //         //src:"",
        //         // type: "video",
        //         // width: null,
        //         // height: null,
        //         // x: 0,
        //         // y: 0,
        //     },
        // }),

        // StepClass.init({
        //     name: "Remove Background",
        //     description: "Remove the background of your video",
        //     type: "video",
        //     action: "remove-bg",
        //     params: {
        //         //src: "",
        //         // type: "video",
        //         // width: null,
        //         // height: null,
        //         // x: 0,
        //         // y: 0,
        //     },
        // }),

        // StepClass.effect({
        //     name: "Upscale Video",
        //     description: "Enhance your video using our ai technology",
        //     comingSoon: true,
        // }),

       
        

        StepClass.init({
            name: "Audio",
            description: "Add an audio to your video",
            action: "add-audio",
            is_enabled: () => {
                if (selectedScene.steps.length > 0) {
                    return true;
                }
                return false;
            },
            type: "audio",
            params: {
                label: "audio",
                // file: null,
                src: "null",
                volume: "0.5",
            },
        }),

        // StepClass.init({
        //     name: "Merge Scenes",
        //     description: "Merge multiple scenes into one",
        //     type: "transition",
        //     action: "merge-scenes",

        //     // parentid: merge_res[0].id,

        //     params: {
        //         label: "Merge Scenes",
        //         option: "fade",
        //         scenes: "null",
        //         duration: "5",
        //         // options: [
        //         //     "fade",
        //         //     "wipe-left",
        //         //     "wipe-right",
        //         //     "slide-left",
        //         //     "slide-right",
        //         //     "slide-up",
        //         //     "slide-down",
        //         //     "circle-crop",
        //         //     "rect-crop",
        //         //     "distance",
        //         //     "fade-black",
        //         //     "smooth-left",
        //         //     "smooth-right",
        //         // ],
        //     },
        // }),
    ];

    const overlaysLibrary = [
        StepClass.video({
            name: "Avatar",
            description: "Add an avatar to your video",
            action: "generate-video",
            // is_enabled: () => {
            //     if (selectedScene.steps?.length === 0) {
            //         return true;
            //     }
            //     return false;
            // },
            params: {
                label: "Avatar",
                src: "null",
                name: "",
                text: "",
                // mediaObj: null,
                // voiceObj: null,

                videourl: "null",
                videoid: "null",

                imageurl: "null",
                imageid: "null",

                audiourl: "null",
                voiceid: "null",
                usevoice: "fromid",

                use_nobg: "false",
                src_nobg: "null",

                width: "null",
                height: "null",
                x: "null",
                y: "null",
            },
        }),
        StepClass.init({
            name: "Video",
            description: "Add a video to your video",
            type: "video",
            action: "overlay-video",
            params: {
                label: "Video",
                src_type: "video",
                src: "null",
                width: "null",
                height: "null",
                x: "null",
                y: "null",
            },
        }),
        StepClass.init({
            name: "Image",
            description: "Add an image to your video",
            type: "image",
            is_enabled: () => {
                if (selectedScene.steps.length > 0) {
                    return true;
                }
                return false;
            },
            action: "overlay-image",
            params: {
                label: "Image",
                src_type: "image",
                src: "null",
                width: "null",
                height: "null",
                x: "null",
                y: "null",
            },
        }),
        StepClass.init({
            name: "Image to Video",
            description: "Convert an image to a video (required to be first step)",
            action: "image2video",
            type: "video",
            // is_enabled: () => {
            //     if (selectedScene.steps?.length === 0) {
            //         return true;
            //     }
            //     return false;
            // },
            params: {
                label: "Image to Video",
                src: "null",
                //type: "image",

                width: "null",
                height: "null",
                x: "null",
                y: "null",

                duration: "5",
                range: "[1, 20]",
            },
        }),

        // StepClass.overlay({
        //     name: "Media",
        //     description: "Overlay a video or image to your video",
        //     type: "video",
        //     action: "overlay-video",
        //     params: {
        //         src: "",
        //         width: null,
        //         height: null,
        //     },
        // }),

        StepClass.init({
            name: "Text",
            description: "Add a text to your video",
            type: "base64",
            is_enabled: () => {
                if (selectedScene.steps.length > 0) {
                    return true;
                }
                return false;
            },
            action: "overlay-text",
            params: {

                label: "Text",
                text: "Add your text here",

                width: 130,
                height: 50,
                x: 0,
                y: 0,
                rotation: 0,

                font_family: "Montserrat",

                font_size: "24",
                font_color: "&Hffffff",

                font_style_bold: "0",
                font_style_italic: "0",

                text_stroke_width: "null",
                text_stroke_color: "#ffffff",

                text_shadow_position: "2",
                text_shadow_color: "#000000",

                text_align : "left"

                
            },
        }),
    ];

    // const effectsLibrary = [
    //     StepClass.effect({
    //         name: "Fade",
    //         description: "Add fade effect to your video",
    //         action: "fade",
    //         params: {
    //             option: "in",
    //             duration: 5,

    //         },

    //     }),

    //     StepClass.effect({
    //         name: "Zoom",
    //         description: "Add zoom effect to your video",
    //         action: "zoom",
    //         params: {
    //             option: "in",
    //             duration: 5,
    //         },

    //     }),

    //     StepClass.effect({
    //         name: "Adjust Brightness",
    //         description: "Adjust the brightness of your video",
    //         action: "adjust-brightness",
    //         params: {
    //             strength: 0.5,
    //         },

    //     }),
    //     StepClass.effect({
    //         name: "Grayscale",
    //         description: "Grayscale your video",
    //         action: "grayscale-video",
    //     }),

    //     StepClass.effect({
    //         name: "Gaussian Blur",
    //         description: "Blur your video",
    //         action: "gaussian-blur",
    //         params: {
    //             strength: 10,
    //         },

    //     }),
    //     StepClass.effect({
    //         name: "Vintage Look",
    //         description: "Add vintage effect to your video",
    //         action: "vintage-look",
    //     }),
    // ];

    useEffect(() => {
        handleList({ type: "effects" });
    }, []);

    const handleSelectStep = async (step) => {
        const theStep = {
            ...step,
            // id: uuidv4(),
            detailType: "steps",
            parentid: selectedScene.id,
            params: typeof step.params === "string" ? JSON.parse(step.params) : step.params,
        };

        console.log("theStep :: ", theStep);

        const stringifiedParams = {};
        for (const [key, value] of Object.entries(theStep.params)) {
            stringifiedParams[key] = value?.toString() || "";
        }

        console.log(stringifiedParams);

        const step_input = {
            index: (selectedScene.steps.length + 1).toString(),
            parentid: selectedScene.id,
            type: theStep.type,
            action: theStep.action,
            ...stringifiedParams, // Use the stringified params instead
        };
        console.log("addstep_input :: ", step_input);
        const addstep_res = await addStep(step_input);
        console.log("addstep_res :: ", addstep_res);

        theStep.id = addstep_res[0].id;
        theStep.label = theStep.params.label;

        setSelectedScene((prev) => ({
            ...prev,
            steps: [...prev.steps, theStep],
        }));

        // setSelectedScene({ ...selectedScene });

        setSelectedDetail(theStep);
        setShowStepAdd(false);
    };

    const handleList = async ({ type }) => {
        switch (type) {
            case "effects":
                try {
                    const res = await listEffects();
                    console.log("res :: ", res);

                    const updatedRes = res.map(effect => {
                        const baseEffect = {
                            ...effect,
                            is_enabled: () => {
                                if (selectedScene.steps.length > 0) {
                                    return true;
                                }
                                return false;
                            }
                        };

                        if (effect.action === "slide") {
                            return {
                                ...baseEffect,
                                name: "Slide Image",
                                description: "Slide in an image to your video",
                                is_enabled: () => {
                                    if (project.proportion === "9:16") {
                                        return true;
                                    }
                                    return false;
                                },
                            };
                        }

                        return baseEffect;
                    });

                    setEffectsLibrary(updatedRes);
                } catch (err) {
                    console.log("listEffects error   :: ", err);
                }
                break;
        }
    };

    const ListItem = ({ step, icon }) => {
        return (
            <div
                className={`transition-all step-library-item border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden  ${
                    typeof step.is_enabled === "function" && !step.is_enabled() ? "opacity-50 cursor-not-allowed" : "hover:border-blue cursor-pointer"
                }`}
                onClick={() => {
                    if (!step.comingSoon && (typeof step.is_enabled !== "function" || step.is_enabled())) {
                        handleSelectStep(step);
                    }
                }}
            >
                <div className="flex flex-row items-start gap-6 p-6 ">
                    <div>{icon}</div>
                    <div className="flex flex-col gap-1">
                        <div className="font-semibold flex flex-row items-center gap-2">{step.name}</div>
                        <div className="text-sm text-black/30">{step.description}</div>
                        <div className="flex flex-row items-center gap-2">
                            {step.comingSoon && (
                                <div className="text-xs  flex flex-row items-center gap-2 text-red-500 bg-red-500/30 rounded-full px-2 py-1">Coming Soon</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col min-h-800 max-h-800 relative overflow-hidden">
            <div className="flex flex-col  gap-8 items-start justify-between p-10">
                <div className=" text-lg">Add Step</div>
                <div className="w-full step-library-search border border-lightblue100 rounded-2xl p-2">
                    <input type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            <div className="flex  flex-1 flex-col gap-4  relative overflow-y-auto p-10 pt-0">
                <div className="text-xs uppercase opacity-50">Overlays</div>
                <div className="step-library-list grid grid-cols-3 gap-4 pb-10">
                    {overlaysLibrary.map((step, s) => (
                        <ListItem key={s} step={step} icon={<IoLayersOutline className="text-xl  text-blue w-7 h-7" />} />
                    ))}
                </div>
                <div className="text-xs uppercase opacity-50">Functions</div>
                <div className="step-library-list grid grid-cols-3 gap-4 pb-10">
                    {functionsLibrary.map((step, s) => (
                        <ListItem key={s} step={step} icon={<LuFunctionSquare className="text-xl  text-blue w-7 h-7" />} />
                    ))}
                </div>

                <div className="text-xs uppercase opacity-50">Effects</div>
                <div className="step-library-list grid grid-cols-3 gap-4 pb-10">
                    {effectsLibrary.map((step, s) => (
                        <ListItem key={s} step={step} icon={<PiMagicWand className="text-xl  text-blue w-7 h-7" />} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StepAdd;
