import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter } from "src/common/utils/utils";
import { useState } from "react";
import { RiMore2Fill } from "react-icons/ri";
import More from "./card-studio-more";
import Tag from "src/common/components/tag/tag";
import { useNavigate } from "react-router-dom";

const CardStudio = (props) => {
    const navigate = useNavigate();
    const { project } = props;
    const [showMore, setShowMore] = useState();



    return (
        <div className="bg-white border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden hover:border-lightblue100/80">
            <div className="flex flex-col gap-6 relative">
                {showMore && (
                    <More
                        project={project}
                        OnClose={() => {
                            setShowMore(false);
                        }}
                    />
                )}

                {project && !props.for && (
                    <div
                        className="absolute right-0 top-3 rounded-full p-3 cursor-pointer"
                        onClick={() => {
                            setShowMore(true);
                        }}
                    >
                        <RiMore2Fill className="text-xl" />
                    </div>
                )}

                <div className="p-6">
                    <div className="text-xl font-semibold leading-snug min-h-32 p-2 cursor-pointer" onClick={() => {
                        navigate(`/admin/studio/${project.id}`);
                        }}>{project.name === "" ? "Untitled" : project.name}</div>
                    <div className="flex items-center justify-between">
                        <div className="opacity-40">{dateFormatter(project.createdate)}</div>

                        <div className="opacity-40 text-xs">{project.id}</div>
                    </div>
                </div>
                {/* <div className="p-10 border-t border-lightblue100">... {campaign.csv.slice(-50)}</div> */}
            </div>
        </div>
    );
};
    
export default CardStudio;
