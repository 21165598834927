import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AdminLayout from "src/layouts/admin-layout";
import Artboard from "src/modules/admin/studio/artboard/artboard";
import Sidebar from "src/modules/admin/studio/sidebar/sidebar";
import Scenes from "src/modules/admin/studio/scene/scenes";
import { LuRectangleHorizontal } from "react-icons/lu";
import { LuRectangleVertical } from "react-icons/lu";
import { LuSquare } from "react-icons/lu";

import SidebarDetail from "src/modules/admin/studio/sidebar-detail/sidebar-detail";
import StepAdd from "src/modules/admin/studio/step/step-add";
import InputSelect from "src/modules/admin/studio/input/input-select";
import Preloader from "src/common/components/preloader/preloader";
import Popup from "src/common/components/popup/popup";
import SceneAdd from "src/modules/admin/studio/scene/scene-add";
import { getProject, updateProject } from "src/common/services/project";

const Studio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const screens = [
        {
            value: "16:9",
            label: "Desktop",
            icon: <LuRectangleHorizontal className="text-lg" />,
        },
        {
            value: "9:16",
            label: "Mobile",
            icon: <LuRectangleVertical className="text-lg" />,
        },
        {
            value: "4:3",
            label: "Square",
            icon: <LuSquare className="text-lg" />,
        },
    ];

    const [fetching, setFetching] = useState(false);
    const [showStepAdd, setShowStepAdd] = useState(false);
    const [showSceneAdd, setShowSceneAdd] = useState(false);
    const [showInputAdd, setShowInputAdd] = useState(false);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });

    const [selectedScene, setSelectedScene] = useState(null);
    const [selectedDetail, setSelectedDetail] = useState(null);

    // imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender
    const [project, setProject] = useState();
    // {
    // id: uuidv4(),
    // name: "Project 1",
    // proportion: "16:9",
    // width: 1280,
    // height: 720,
    // scenes: [],
    // }

    const [scenes, setScenes] = useState([]);

    // Add these refs before the useEffect
    const prevProjectRef = useRef(project);
    const prevSelectedSceneRef = useRef(selectedScene);

    useEffect(() => {
        if (!selectedScene || !project) return;
        //console.log("selectedScene :: ", selectedScene);
        // Sync result from project to selectedScene
        // const currentSceneResult = project.scenes.find((scene) => scene.id === selectedScene.id)?.result;
        // if (currentSceneResult !== selectedScene.result) {
        //     setSelectedScene((prev) => ({
        //         ...prev,
        //         result: currentSceneResult,
        //     }));
        //     return;
        // }

        // Determine update source and sync accordingly
        // const existingScene = project.scenes.find((scene) => scene.id === selectedScene.id);
        // if (!existingScene) return;

        // if (prevSelectedSceneRef.current !== selectedScene) {
        //     prevSelectedSceneRef.current = selectedScene;
        //     if (JSON.stringify(existingScene) !== JSON.stringify(selectedScene)) {
        //         setProject((prev) => ({
        //             ...prev,
        //             scenes: prev.scenes.map((scene) => (scene.id === selectedScene.id ? selectedScene : scene)),
        //         }));
        //     }
        // } else if (prevProjectRef.current !== project) {
        //     // Update originated from project
        //     prevProjectRef.current = project;
        //     if (JSON.stringify(existingScene) !== JSON.stringify(selectedScene)) {
        //         setSelectedScene(existingScene);
        //     }
        // }

        setScenes((prev) => prev.map((scene) => (scene.id === selectedScene.id ? selectedScene : scene)));
    }, [selectedScene]); // Simplified dependency array

    useEffect(() => {
        console.log("selectedDetail :: ", selectedDetail);
        if (!selectedScene || !project || !selectedDetail || selectedDetail.parentid !== selectedScene.id) return;

        setSelectedScene((prev) => ({
            ...prev,
            steps: prev.steps.map((detail) => (detail.id === selectedDetail?.id ? selectedDetail : detail)),
        }));

        // if (selectedDetail.detailType === "input") {
        //     setSelectedScene((prev) => ({
        //         ...prev,
        //         input: selectedDetail,
        //     }));
        // } else {

        //     setSelectedScene((prev) => ({
        //         ...prev,
        //         [selectedDetail.detailType]: prev[selectedDetail.detailType].map((detail) => (detail.id === selectedDetail?.id ? selectedDetail : detail)),
        //     }));
        // }
    }, [selectedDetail]);

    useEffect(() => {
        if (!params?.projectid) return;
        handleProject({ opr: "get", id: params?.projectid });
    }, [params]);

    const handleProject = async ({ opr, id }) => {
        try {
            switch (opr) {
                case "get":
                    const res = await getProject({ id });
                    // console.log("project :: ", project);
                    console.log("project res :: ", res);
                    const r = res[0];

                    const initProject = {
                        ...r,
                        width: r.width === "" ? 1280 : parseInt(r.width),
                        height: r.height === "" ? 720 : parseInt(r.height),
                        scenes: [],
                    };
                    setProject(initProject);
                    //     setProject(res.data);
                    break;
            }
        } catch (error) {
            console.log("handleProject error :: ", error);
        }
    };

    // useEffect(() => {
    //     console.log("project :: ", project);
    // }, [project]);

    return (
        <>
            {fetching && <Preloader />}
            <AdminLayout type="full">
                {showSceneAdd && (
                    <Popup
                        className="!w-full md:!w-7xl"
                        OnHide={() => {
                            setShowSceneAdd(false);
                        }}
                    >
                        <SceneAdd
                            setFetching={setFetching}
                            project={project}
                            scenes={scenes}
                            setScenes={setScenes}
                            selectedDetail={selectedDetail}
                            setSelectedDetail={setSelectedDetail}
                            selectedScene={selectedScene}
                            setSelectedScene={setSelectedScene}
                            stageSize={stageSize}
                            showSceneAdd={showSceneAdd}
                            setShowSceneAdd={setShowSceneAdd}
                        />
                    </Popup>
                )}

                {showStepAdd && (
                    <Popup
                        className="!w-full md:!w-7xl"
                        OnHide={() => {
                            setShowStepAdd(false);
                        }}
                    >
                        <StepAdd
                            project={project}
                            setSelectedDetail={setSelectedDetail}
                            selectedScene={selectedScene}
                            setSelectedScene={setSelectedScene}
                            setShowStepAdd={setShowStepAdd}
                        />
                    </Popup>
                )}

                {showInputAdd && (
                    <Popup
                        className="!w-full md:!w-7xl"
                        OnHide={() => {
                            setShowInputAdd(false);
                        }}
                    >
                        <InputSelect
                            selectedScene={selectedScene}
                            selectedDetail={selectedDetail}
                            setSelectedDetail={setSelectedDetail}
                            setSelectedScene={setSelectedScene}
                            setShowInputAdd={setShowInputAdd}
                        />
                    </Popup>
                )}

                <div className="studio-container absolute left-0 top-0 h-full w-full flex-1 flex flex-row gap-4 relative overflow-hidden">
                    {project && (
                        <>
                            <Sidebar
                                screens={screens}
                                scenes={scenes}
                                setScenes={setScenes}
                                project={project}
                                setProject={setProject}
                                selectedScene={selectedScene}
                                setSelectedScene={setSelectedScene}
                                selectedDetail={selectedDetail}
                                setSelectedDetail={setSelectedDetail}
                                setShowStepAdd={setShowStepAdd}
                                setShowInputAdd={setShowInputAdd}
                                stageSize={stageSize}
                            />
                            <div className="main-container flex-1 flex flex-col gap-4">
                                <div className="artboard-container bg-dots bg-indigo-300/10  rounded-2xl border border-lightblue100  flex-1  flex flex-row gap-4 p-4">
                                    {selectedScene && selectedDetail && Object.keys(selectedDetail.params).length > 0 && (
                                        <SidebarDetail
                                            project={project}
                                            setProject={setProject}
                                            stageSize={stageSize}
                                            setStageSize={setStageSize}
                                            selectedDetail={selectedDetail}
                                            setSelectedDetail={setSelectedDetail}
                                            selectedScene={selectedScene}
                                            setSelectedScene={setSelectedScene}
                                            setShowInputAdd={setShowInputAdd}
                                        />
                                    )}
                                    <Artboard
                                        screens={screens}
                                        project={project}
                                        setProject={setProject}
                                        selectedDetail={selectedDetail}
                                        setSelectedDetail={setSelectedDetail}
                                        selectedScene={selectedScene}
                                        setSelectedScene={setSelectedScene}
                                        stageSize={stageSize}
                                        setStageSize={setStageSize}
                                    />
                                </div>
                                <Scenes
                                    project={project}
                                    setProject={setProject}
                                    scenes={scenes}
                                    setScenes={setScenes}
                                    selectedScene={selectedScene}
                                    setSelectedScene={setSelectedScene}
                                    selectedDetail={selectedDetail}
                                    setSelectedDetail={setSelectedDetail}
                                    stageSize={stageSize}
                                    showSceneAdd={showSceneAdd}
                                    setShowSceneAdd={setShowSceneAdd}
                                />
                            </div>
                        </>
                    )}
                </div>
            </AdminLayout>
        </>
    );
};

export default Studio;
