import { PiAlignTopSimple, PiAlignCenterVerticalSimple, PiAlignBottomSimple } from "react-icons/pi";

export const positions = (stageSize, subtitleDimension) => {
    return [
        {
            id: "top",
            icon: <PiAlignTopSimple className="text-xl" />,
            label: "top",
            x: stageSize.width / 2 - subtitleDimension.width / 2,
            y: 10,
        },
        {
            id: "center",
            icon: <PiAlignCenterVerticalSimple className="text-xl" />,
            label: "center",
            x: stageSize.width / 2 - subtitleDimension.width / 2,
            y: stageSize.height / 2 - subtitleDimension.height / 2,
        },
        {
            id: "bottom",
            icon: <PiAlignBottomSimple className="text-xl" />,
            label: "bottom",
            x: stageSize.width / 2 - subtitleDimension.width / 2,
            y: stageSize.height - subtitleDimension.height - 100,
        },
    ];
};

export const fitTypes = (selectedDetail, stageSize) => {
    const contain = () => {
        const imgWidth = selectedDetail.params.width;
        const imgHeight = selectedDetail.params.height;
        const stageRatio = stageSize.width / stageSize.height;
        const imgRatio = imgWidth / imgHeight;

        let newWidth, newHeight;

        if (imgRatio > stageRatio) {
            // Image is wider than the stage, scale based on width
            newWidth = stageSize.width;
            newHeight = (stageSize.width / imgWidth) * imgHeight;
        } else {
            // Image is taller than the stage, scale based on height
            newHeight = stageSize.height;
            newWidth = (stageSize.height / imgHeight) * imgWidth;
        }

        const newX = (stageSize.width - newWidth) / 2;
        const newY = (stageSize.height - newHeight) / 2;
        return { width: newWidth, height: newHeight, x: newX, y: newY };
    };

    const cover = () => {
        const imgWidth = selectedDetail.params.width;
        const imgHeight = selectedDetail.params.height;
        const stageRatio = stageSize.width / stageSize.height;
        const imgRatio = imgWidth / imgHeight;

        let newWidth, newHeight;

        // Cover the entire stage by scaling the image based on the smaller ratio (height or width)
        if (imgRatio > stageRatio) {
            // Image is wider, scale based on height
            newHeight = stageSize.height;
            newWidth = (stageSize.height / imgHeight) * imgWidth;
        } else {
            // Image is taller, scale based on width
            newWidth = stageSize.width;
            newHeight = (stageSize.width / imgWidth) * imgHeight;
        }

        // Center the image
        const newX = (stageSize.width - newWidth) / 2;
        const newY = (stageSize.height - newHeight) / 2;

        return { width: newWidth, height: newHeight, x: newX, y: newY };
    };

    const fits = [
        {
            id: "contain",

            width: contain().width,
            height: contain().height,
            x: contain().x,
            y: contain().y,
        },
        {
            id: "cover",

            width: cover().width,
            height: cover().height,
            x: cover().x,
            y: cover().y,
        },
    ];
    return fits;
};
