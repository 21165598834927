import UiSelect from "src/modules/admin/studio/components/ui-select";
import React from "react";
import RangeSlider from "./range-slider";
import UiDSection from "../../components/ui-dsection";
import { useEffect, useState } from "react";

const Range = ({ selectedScene, selectedDetail, setSelectedDetail }) => {
    const [videoDuration, setVideoDuration] = useState(null);

    useEffect(() => {
        let videoSrc = null;
        if (selectedDetail.params.src && selectedDetail.type === "video") {
            videoSrc = selectedDetail.params.src;
        } 
        
        if (videoSrc) {
            const video = document.createElement("video");
            video.src = videoSrc;

            video.onloadedmetadata = () => {
                setVideoDuration(video.duration);
            };
        }
    }, [selectedScene]);

    const handleChange = (key, value) => {
        setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, [key]: value } }));
    };

    return (
        <div className="flex flex-col gap-2">
            {Object.entries(selectedDetail.params).map(([key, value]) => {
                let min = 1;
                let max = 10;
                let step = 0.01;
                let range = [1, 10]; // default range

                if (selectedDetail.params?.range) {
                    try {
                        if (typeof selectedDetail.params.range === "string") {
                            range = JSON.parse(selectedDetail.params.range);
                        } else if (Array.isArray(selectedDetail.params.range)) {
                            range = selectedDetail.params.range;
                        }
                    } catch (error) {
                        console.warn("Invalid range format:", error);
                    }
                }

                min = range[0] !== undefined ? parseFloat(range[0]) : 5;
                max = (range[1] !== undefined ? parseFloat(range[1]) : videoDuration) || 10;
                step = (max - min) / 20;

                return (
                    <React.Fragment key={key}>
                        {(key === "duration" || key === "strength") && (
                            <UiDSection >
                                <RangeSlider
                                    title={key}
                                    initialValue={selectedDetail.params[key]}
                                    min={min}
                                    max={max}
                                    step={step}
                                    OnValue={(val) => handleChange(key, val)}
                                />
                            </UiDSection>
                        )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default Range;
