import { v4 as uuidv4 } from "uuid";

export default class StepClass {
    static placeholderVideo = "https://svc.staging.squadz.live/download/11111111-1111-1111-1111-111111111111/11111111-1111-1111-1111-111111111111/OB544X1ac6b57/1280x720.mp4";
    static placeholderImage = "https://svc.staging.squadz.live/download/11111111-1111-1111-1111-111111111111/11111111-1111-1111-1111-111111111111/OB544X1ac6b57/1280x720.mp4/frame1.jpg";

    constructor(data = {}) {
        this.id = uuidv4();

        this.action = data.action || "";
        this.type = data.type || "";

        this.name = data.name || "";
        this.description = data.description || "";

        this.detailType = "steps";

        // Initialize params object
        this.params = {};

        if (data.options) {
            this.options = data.options;
        }
        if (data.range) {
            this.range = data.range;
        }

        if (data.comingSoon) {
            this.comingSoon = data.comingSoon;
        }

        this.is_enabled = data.is_enabled;
        this.parentid = data.parentid;
        this.result = data.result;

        // Handle all possible params with a single loop
        const paramKeys = [
            "action",
            "parentid",
            "label",
            "input_src",
            "src",

            "src_nobg",
            "use_nobg",
            "type",
            "name",

            "x",
            "y",
            "width",
            "height",
            "rotation",

            "genVidId",

            "mediaObj",
            "voiceObj",

            "option",
            "options",

            "duration",
            "strength",
            "range",

            "scenes",

            "volume",

            "file",

            "position",

            "text",
            "font_family",
            "font_size",
            "font_color",
            "font_style",
            "font_weight",
            "font_align",
            "text_shadow",
            "text_stroke",

            "font_style_bold",
            "font_style_italic",

            "text_stroke_width",
            "text_stroke_color",

            "text_shadow_position",
            "text_shadow_color",
            "text_align",

            "videourl",
            "videoid",
            "imageurl",
            "imageid",

            "audiourl",
            "voiceid",
            "usevoice",

            "logo",
            "price",
            "address",
            "beds",
            "baths",
            "sqft",
            "agent_name",
            "agent_email",
            "agent_contact",
        ];

        paramKeys.forEach((key) => {
            if (data.params?.[key] !== undefined) {
                this.params[key] = data.params[key];
            }
        });

        // Special case for font object
        if (this.type === "text" && data.params?.font) {
            this.params.font = {
                name: data.params.font?.name,
                style: data.params.font?.style,
                size: data.params.font?.size,
                color: data.params.font?.color,
            };
        }
    }

    static init(data = {}) {
        return new StepClass(data);
    }

    static video(data = {}) {
        return new StepClass({
            action: data.action || "overlay-video",
            type: "video",
            ...data,
        });
    }

    static overlay(data = {}) {
        return new StepClass({
            action: data.action || "overlay-video",
            type: data.type || "video",
            ...data,
        });
    }

    static avatar(data = {}) {
        return new StepClass({
            action: data.action || "generate-video",
            type: data.type || "avatar",
            ...data,
        });
    }

    static audio(data = {}) {
        return new StepClass({
            action: data.action || "add-audio",
            type: "audio",
            ...data,
        });
    }

    static effect(data = {}) {
        return new StepClass({
            action: data.action || "zoom",
            type: data.type || "effect",
            ...data,
        });
    }

    static transition(data = {}) {
        return new StepClass({
            action: data.action || "merge-scenes",
            type: "transition",
            params: {
                ...data.params,
                options: [
                    "fade",
                    "wipe-left",
                    "wipe-right",
                    "slide-left",
                    "slide-right",
                    "slide-up",
                    "slide-down",
                    "circle-crop",
                    "rect-crop",
                    "distance",
                    "fade-black",
                    "smooth-left",
                    "smooth-right",
                ],
            },
            ...data,
        });
    }

    //https://svc.staging.squadz.live/download/11111111-1111-1111-1111-111111111111/11111111-1111-1111-1111-111111111111/OB544X1ac6b57/1280x720.mp4
    //https://svc.staging.squadz.live/download/11111111-1111-1111-1111-111111111111/11111111-1111-1111-1111-111111111111/OB545qec5dee9/720x1280.mp4"

    static avatar(data = {}) {
        return new StepClass({
            label: "Avatar",
            action: "generate-video",
            type: "video",
            params: {
                label: "Avatar",
                src: data.src || "null",
                name: data.name || "",
                text: data.text || "",
                videourl: data.videourl || "null",
                videoid: data.videoid || "null",
                imageurl: data.imageurl || "null",
                imageid: data.imageid || "null",
                audiourl: data.audiourl || "null",
                voiceid: data.voiceid || "null",
                usevoice: data.usevoice || "fromid",
                use_nobg: data.use_nobg || "false",
                src_nobg: data.src_nobg || "null",
                width: data.width || "null",
                height: data.height || "null",
                x: data.x || "null",
                y: data.y || "null",
            },
        });
    }

    static realestateFirstPage(data = {}) {
        return new StepClass({
            label: "Real Estate First Page",
            action: "realestate-first-page",

            type: "video",
            params: {
                label: "Real Estate First Page",
                src: data.src || this.placeholderImage,
                duration: data.duration || "3",
                logo: data.logo || "",
                price: data.price || "0",
                address: data.address || "Address Here",
                beds: data.beds || "0",
                baths: data.baths || "0",
                sqft: data.sqft || "0",
            },
        });
    }

    static realestateLastPage(data = {}) {
        return new StepClass({
            label: "Real Estate Last Page",
            action: "realestate-last-page",
            type: "video",
            params: {
                label: "Real Estate Last Page",
                src: data.src || this.placeholderImage,
                duration: data.duration || "3",
                logo: data.logo || "",
                agent_name: data.agent_name || "Agent Name",
                agent_email: data.agent_email || "email@example.com",
                agent_contact: data.agent_contact || "000-000-0000",
            },
        });
    }

    static overlayVideo(data = {}) {
        return new StepClass({
            label: "Video",
            action: "overlay-video",
            type: "video",
            params: {
                label: "Overlay Video",
                src_type: "video",
                src: data.src || StepClass.placeholderVideo,
                width: data.width || "null",
                height: data.height || "null",
                x: data.x || "null",
                y: data.y || "null",
            },
        });
    }

    static image2video(data = {}) {
        return new StepClass({
            label: "Image to Video",
            action: "image2video",
            type: "video",
            params: {
                label: "Image to Video",
                src: data.src || this.placeholderImage,
                width: data.width || "null",
                height: data.height || "null",
                x: data.x || "null",
                y: data.y || "null",
                duration: data.duration || "5",
            },
        });
    }

    static slide(data = {}) {
        return new StepClass({
            label: "Slide Image",
            action: "slide",
            type: "effect",
            params: {
                option: data.option || "left",
                src: data.src || this.placeholderImage,
                duration: data.duration || "5",
            },
        });
    }

    static zoom(data = {}) {
        return new StepClass({
            label: "Zoom",
            action: "zoom",
            type: "effect",
            params: {
                label: "Zoom",
                option: data.option || "in",
                duration: data.duration || "5",
            },
        });
    }

    static subtitles(data = {}) {
        return new StepClass({
            label: "Subtitle",
            action: "subtitles",
            type: "video",
            params: {
                label: "Subtitle",
               // src: data.src || this.placeholderVideo,
                highlight: data.highlight || "false",
                width: data.width || "null",
                height: data.height || "null",
                x: data.x || "null",
                y: data.y || "null",
                position: data.position || "bottom",
                font_family: data.font_family || "Montserrat",
                font_size: data.font_size || "16",
                font_color: data.font_color || "&Hffffff",
                font_style_bold: data.font_style_bold || "-1",
                font_style_italic: data.font_style_italic || "0",
                text_stroke_color: data.text_stroke_color || "&Hffffff",
                text_shadow_position: data.text_shadow_position || "2",
                text_shadow_color: data.text_shadow_color || "&H000000",
            },
        });
    }
}
