import { textFonts } from "src/common/data/fonts";
import UiSelect from "../../components/ui-select";
import UiColorPicker from "../../components/ui-color-picker";
import UiDSection from "../../components/ui-dsection";
import { FaItalic, FaBold } from "react-icons/fa";
import { positions } from "../../common";
import SubtitleStyles from "../../templates/subtitle-styles";

const Font = (props) => {
    const { selectedDetail, setSelectedDetail, open, stageSize, selectedScene } = props;

    const parsedParams = selectedDetail?.params ? (typeof selectedDetail.params === "string" ? JSON.parse(selectedDetail.params) : selectedDetail.params) : {};

    // Create font family options
    const fontFamilyOptions = Object.keys(textFonts).map((font) => ({
        label: font,
        value: font,
        style: { fontFamily: font },
    }));

    const fontSizes = [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128];
    const fontSizeOptions = fontSizes.map((size) => ({
        label: `${size}px`,
        value: `${size}`,
    }));

    const predefinedSizes = [
        {
            label: "Small",
            value: "10",
        },
        {
            label: "Medium",
            value: "16",
        },
        {
            label: "Large",
            value: "24",
        },
    ];


    const textAlignOptions = ["left", "center", "right"];

    // Create font style options based on selected font family
    const getFontStyleOptions = (selectedFont) => {
        console.log(selectedFont);
        if (!selectedFont) return [];
        return textFonts[selectedFont].map((style) => ({
            label: style.style,
            value: style.value,
        }));
    };

    const handleFont = ({ opr, key, value }) => {
        switch (opr) {
            case "select":
                if (key === "font_style_bold" || key === "font_style_italic") {
                    // Toggle between -1 and 0 for bold, or 1 and 0 for italic
                    const currentValue = parsedParams[key] || 0;
                    value = key === "font_style_bold" ? (currentValue === -1 ? 0 : -1) : currentValue === 1 ? 0 : 1;
                }
                setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, [key]: value } }));
                break;
        }
    };

    return (
        <>
            <UiDSection title="Styles" className="flex flex-col gap-2">
                
                <div className="flex-1 flex flex-row gap-2 items-center justify-center">
                    <SubtitleStyles selectedScene={selectedScene} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} onSelect={() => {}} />
                </div>

                <div className="flex flex-row items-center gap-2">
                    {predefinedSizes.map((size) => (
                        <div
                            key={size.value}
                            className={`flex-1 flex items-center justify-center cursor-pointer h-12 border border-lightblue100 rounded-lg p-2 ${
                                parsedParams.font_size === size.value ? "bg-lightblue100" : ""
                            }`}
                            onClick={() => handleFont({ opr: "select", key: "font_size", value: size.value })}
                        >
                            {size.label}
                        </div>
                    ))}
                </div>

                {selectedDetail?.action !== "subtitles" && (
                    <div className="flex flex-row items-center gap-2">
                        {textAlignOptions.map((align) => (
                        <div
                            key={align}
                            className={`capitalize flex-1 flex items-center justify-center cursor-pointer h-12 border border-lightblue100 rounded-lg p-2 ${
                                parsedParams.text_align === align ? "bg-lightblue100" : ""
                            }`}
                            onClick={() => handleFont({ opr: "select", key: "text_align", value: align })}
                        >
                            {align}
                        </div>
                        ))}
                    </div>
                )}
            </UiDSection>

            <UiDSection title="Font" open={false}>
                <div className="flex flex-row items-center gap-2">
                    {parsedParams.font_family && (
                        <UiSelect
                            label="Font Family"
                            options={fontFamilyOptions}
                            value={parsedParams?.font_family}
                            optionStyle={(option) => option.style}
                            onChange={(value) => handleFont({ opr: "select", key: "font_family", value })}
                        />
                    )}

                    {parsedParams.font_style && (
                        <UiSelect
                            label="Font Style"
                            options={getFontStyleOptions(parsedParams.font_family)}
                            value={parsedParams.font_style || getFontStyleOptions(parsedParams.font_family)[0]?.value}
                            onChange={(value) => handleFont({ opr: "select", key: "font_style", value })}
                        />
                    )}
                </div>
                <div className="flex flex-row items-center gap-2">
                    {parsedParams.font_size && (
                        <UiSelect
                            label="Font Size"
                            options={fontSizeOptions}
                            value={parsedParams.font_size}
                            onChange={(value) => handleFont({ opr: "select", key: "font_size", value })}
                        />
                    )}
                    {parsedParams.font_color && (
                        <UiColorPicker
                            pickerPos="bottom"
                            label="Font Color"
                            showDefaultColors={false}
                            value={parsedParams.font_color}
                            setValue={(value) => handleFont({ opr: "select", key: "font_color", value })}
                        />
                    )}
                </div>

                <div className="flex flex-row items-center gap-2">
                    <FaBold
                        className={`cursor-pointer w-9 h-9 border border-lightblue100 rounded-lg p-2 ${
                            parsedParams.font_style_bold === -1 ? "bg-lightblue100" : ""
                        }`}
                        onClick={() => handleFont({ opr: "select", key: "font_style_bold" })}
                    />
                    <FaItalic
                        className={`cursor-pointer w-9 h-9 border border-lightblue100 rounded-lg p-2 ${
                            parsedParams.font_style_italic === 1 ? "bg-lightblue100" : ""
                        }`}
                        onClick={() => handleFont({ opr: "select", key: "font_style_italic" })}
                    />
                </div>
            </UiDSection>

            {parsedParams.text_shadow_position && (
                <UiDSection title="Text Shadow" open={false}>
                    <div className="flex flex-row items-center gap-2">
                        <UiSelect
                            label="Text Shadow"
                            options={[
                                {
                                    label: "None",
                                    value: "0",
                                    // css_value: "0 0 0 transparent",
                                },
                                {
                                    label: "Small",
                                    value: "2",
                                    // css_value: "2px 2px 0px 0px rgba(0, 0, 0, 0.8)",
                                },
                                {
                                    label: "Medium",
                                    value: "4",
                                    // css_value: "4px 4px 0px 0px rgba(0, 0, 0, 0.8)",
                                },
                                {
                                    label: "Large",
                                    value: "8",
                                    //css_value: "8px 8px 0px 0px rgba(0, 0, 0, 0.8)",
                                },
                            ]}
                            value={parsedParams.text_shadow_position || "2"}
                            onChange={(value) => {
                                handleFont({
                                    opr: "select",
                                    key: "text_shadow_position",
                                    value: value,
                                });
                            }}
                        />
                        <UiColorPicker
                            pickerPos="bottom"
                            label="Shadow Color"
                            showDefaultColors={false}
                            value={parsedParams.text_shadow_color || "#000000"}
                            setValue={(value) => {
                                handleFont({
                                    opr: "select",
                                    key: "text_shadow_color",
                                    value: value,
                                });
                            }}
                        />
                    </div>
                </UiDSection>
            )}

            {(parsedParams.text_stroke_width || parsedParams.text_stroke_color) && (
                <UiDSection title="Text Stroke" open={false}>
                    <div className="flex flex-row items-center gap-2">
                        {parsedParams.text_stroke_width && (
                            <UiSelect
                                label="Stroke Width"
                                options={[
                                    { label: "0px", value: "0px" },
                                    { label: "1px", value: "1px" },
                                    { label: "2px", value: "2px" },
                                    { label: "3px", value: "3px" },
                                    { label: "4px", value: "4px" },
                                ]}
                                value={parsedParams.text_stroke_width || "0px"}
                                onChange={(value) => {
                                    handleFont({
                                        opr: "select",
                                        key: "text_stroke_width",
                                        value: value,
                                    });
                                }}
                            />
                        )}

                        {parsedParams.text_stroke_color && (
                            <UiColorPicker
                                pickerPos="bottom"
                                label="Stroke Color"
                                showDefaultColors={false}
                                value={parsedParams.text_stroke_color}
                                setValue={(value) => {
                                    handleFont({
                                        opr: "select",
                                        key: "text_stroke_color",
                                        value: value,
                                    });
                                }}
                            />
                        )}
                    </div>
                </UiDSection>
            )}
        </>
    );
};

export default Font;
