import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import UiUploader from "src/modules/admin/studio/components/ui-uploader";
import { useEffect, useState } from "react";
import Popup from "src/common/components/popup/popup";
import ListVoices from "src/modules/admin/voices/list-voices";
import CardAudio from "src/modules/admin/voices/card-audio";
import VideoPlayer from "src/common/components/video-player/video-player";
import { getVoice } from "src/common/services/voices";
import Media from "./media";
import Enhancement from "./enhancement";
const Avatar = (props) => {
    const { selectedScene, selectedDetail, setSelectedDetail, setShowInputAdd } = props;
    const [showSelectVoice, setShowSelectVoice] = useState(false);
    // Parse params if they're in string format, with null check
    const parsedParams = selectedDetail?.params ? (typeof selectedDetail.params === "string" ? JSON.parse(selectedDetail.params) : selectedDetail.params) : {};

    useEffect(() => {
        // Add null check before accessing params
        if (selectedDetail?.params?.voiceid && selectedDetail.params.voiceid !== "null") {
            getVoice({ voiceid: selectedDetail.params.voiceid }).then((voice) => {
                setSelectedDetail((prev) => ({
                    ...prev,
                    params: { ...(prev?.params || {}), voiceObj: voice[0] },
                }));
            });
        }
    }, []);

    return (
        <>
            {showSelectVoice && (
                <Popup
                    className="!w-full md:!w-7xl"
                    OnHide={() => {
                        setShowSelectVoice(false);
                    }}
                >
                    <div className="flex flex-col min-h-600 max-h-600 relative overflow-hidden">
                        <div className="flex flex-col p-10 gap-8">
                            <div className=" text-lg">Select Voice</div>
                        </div>
                        <div className="flex  flex-1 flex-col gap-4  relative overflow-y-auto p-10 pt-0">
                            <ListVoices
                                cols={3}
                                thumbsize={"300"}
                                // selectedId={theVideo.avatar.video?.id}
                                for={`select`}
                                paging={"manual"}
                                OnUse={(voice) => {
                                    setSelectedDetail((prev) => ({
                                        ...prev,
                                        params: {
                                            ...prev.params,
                                            voiceObj: voice,
                                        },
                                    }));
                                    setShowSelectVoice(false);
                                }}
                            />
                        </div>
                    </div>
                </Popup>
            )}
            <UiDSection title="Avatar Name" required={true}>
                <input
                    type="text"
                    placeholder="Add Avatar Name"
                    className="w-full border border-lightblue100 rounded-xl p-2"
                    value={selectedDetail.params.name}
                    onChange={(e) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, name: e.target.value } }))}
                />
            </UiDSection>
            <div className="flex flex-col">
                <UiDSection title="Avatar" required={true}>
                    <Media
                        showMediaSelect={
                            selectedDetail.params.videoid !== "null" ||
                            selectedDetail.params.imageid !== "null" ||
                            selectedDetail.params.videourl !== "null" ||
                            selectedDetail.params.imageurl !== "null"
                                ? false
                                : true
                        }
                        valueObj={parsedParams}
                        mediaType={selectedDetail.type}
                        onSelect={(valueObj) => {
                            setSelectedDetail((prev) => ({
                                ...prev,
                                params: {
                                    ...prev.params,
                                    ...valueObj,
                                    use_nobg: false,
                                    src_nobg: "",
                                },
                            }));
                        }}
                        selectedDetail={{ ...selectedDetail, params: parsedParams }}
                    />
                </UiDSection>

                {parsedParams.videoid !== "null" && (
                    <UiDSection title="Enhancements">
                        <Enhancement selectedDetail={{ ...selectedDetail, params: parsedParams }} setSelectedDetail={setSelectedDetail} />
                    </UiDSection>
                )}

                <UiDSection title="Voice" required={true}>
                    {selectedDetail.params.voiceObj ? (
                        <div className="flex flex-col gap-2">
                            <CardAudio
                                voice={selectedDetail.params.voiceObj}
                                for={"avatar"}
                                actions={
                                    <button
                                        className="button button-small text-xs px-2"
                                        onClick={() => {
                                            setShowSelectVoice(true);
                                        }}
                                    >
                                        change
                                    </button>
                                }
                            />
                        </div>
                    ) : (
                        <div
                            className="transition min-h-32 max-h-32 min-w-full flex-1 flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-2xl cursor-pointer hover:border-blue bg-blue/10 text-black/30 hover:text-blue items-center justify-center"
                            onClick={() => {
                                setShowSelectVoice(true);
                            }}
                        >
                            <button className="relative z-10 button-white p-2  px-4 rounded-3xl cursor-pointer flex items-center gap-2 capitalize">
                                Select Voice
                            </button>
                        </div>
                    )}
                </UiDSection>

                <UiDSection title="Script" required={true}>
                    <div className="flex flex-1 flex-col gap-4 w-full min-h-52 border border-lightblue100 rounded-xl">
                        <textarea
                            className="w-full h-full !p-4"
                            placeholder="Write your script here..."
                            value={selectedDetail.params.text}
                            rows={5}
                            maxLength={500}
                            onChange={(e) => {
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, text: e.target.value },
                                }));
                            }}
                        ></textarea>
                        <div className="text-right text-sm text-gray-500 px-4 pb-5">{selectedDetail.params.text?.length || 0}/500 characters</div>
                    </div>
                </UiDSection>
                {/* {selectedDetail.params.mediaObj && selectedDetail.params.mediaObj.result && (
                    <UiDSection title="Options">
                        <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center justify-between gap-2">
                            <div className="text-sm">Remove Background </div>
                            <Knob
                                value={selectedDetail.params.nobg}
                                OnChange={(value) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, nobg: value } }))}
                            />
                        </div>
                        </div>
                    </UiDSection>
                )} */}
            </div>
        </>
    );
};

export default Avatar;
