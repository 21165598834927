import "./popup.css";

const Popup = (props) => {

    
    return (
        <>
            <div
                className="popup-container fadeIn"
                onClick={() => {
                  
                    if (props.OnHide) {
                        props.OnHide(true);
                    }
                }}
            ></div>

            <div className={`transition-all popup-content rounded-3xl fadeIn w-full md:max-w-7xl  !rounded-3xl
                ${(props.className)? props.className : '' }
                
                `} >{props.children}</div>
        </>
    );
};

export default Popup;
