import logo from "src/common/assets/images/logo.svg";
import "./preloader.css";

const Preloader = (props) => {
    return (
        <>
            {props.type === "inline" ? (
                <svg
                    className={`animate-spin -ml-1 mr-3 h-5 w-5 text-${props.color ? props.color : "blue"}`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            ) : (
                <div className={`preloader`}>
                    <div
                        className={`preloader-bg ${props.bg ? `preloader-bg-${props.bg}` : ""} rounded-2xl ${props.type ? `preloader-${props.type}` : ""}`}
                    ></div>
                    <div className="preloader-content flex flex-col gap-5">
                        {props.children ? (
                            props.children
                        ) : (
                            <>
                                {props.logo !== false && <img src={logo} className="picon" />}
                                <div className="blinking loading">{props.text ? props.text : "Please wait"}</div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default Preloader;
