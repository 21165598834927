import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";




export const chain = async (projectid, sceneid, input) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}mediabot/${projectid}/${sceneid}`,
        data: {
            value: JSON.stringify(input),
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};




export const listEffects = async () => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}mediabot/effects/all`,
        data: {},
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
